import React, { useEffect } from 'react'

export default function Welcome() {

    return <>
        <div className='container-fluid w' >
            <img src="cat-kitten.gif"></img>
            <h2> Welcome, !
            </h2><h6>Select the user to start conversation</h6></div>
    </>

}