import { MoreVertical, ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from "react";
import { IconButton, Avatar } from "@mui/material";

const SidebarContext = createContext();

export default function Sidebar({ children, Doctor_details }) {
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // Handler for menu button click
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event);
    };

    // Handler to close the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <aside className={`h-screen shadow-md transition-width duration-300 ${expanded ? "w-64" : "w-20"}`} id="sidebar">
            <nav className="h-full flex flex-col border-r shadow-sm">
                <div className="p-3 pb-2 flex justify-between items-center">
                    <img
                        src="https://aiwell.in/static/media/Aiwell%20version%202%20website%20logo.d98414bba22690b67307.png"
                        className={`overflow-hidden transition-all ${expanded ? "w-40" : "w-0"}`}
                        alt=""
                    />
                    <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                    >
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3 mt-8">{children}</ul>
                </SidebarContext.Provider>

                <div className="border-t flex p-3">
                    <Avatar alt="Remy Sharp" src={Doctor_details?.img} />
                    <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
                        <div className="leading-4">
                            <h4 className="font-semibold">{Doctor_details?.name}</h4>
                            <span className="text-xs text-gray-600">{Doctor_details?.email}</span>
                        </div>

                        <IconButton onClick={handleClick}>
                            <MoreVertical size={20} />
                        </IconButton>
                    </div>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <div className="menuitem">
                            <p>Logout</p>
                            <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/log+out+icon.png" alt="Logout Icon"></img>
                        </div>
                    </MenuItem>
                </Menu>
            </nav>
        </aside>
    );
}

export function SidebarItem({ icon, text, active, alert }) {
    const { expanded } = useContext(SidebarContext);

    return (
        <li
            className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group  ${active ? "text-[#75623F] bg-[#FDFDFD]" : "hover:bg-[#D4CEC3] text-gray-600"}`}
        >
            {icon}
            <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>
                {text}
            </span>
            {alert && (
                <div className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`} />
            )}

            {!expanded && (
                <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                    {text}
                </div>
            )}
        </li>
    );
}
