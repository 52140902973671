import create from 'zustand';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import { getUserDetails } from '../Auth/AuthUtil';
import PatientList from '../Appointments/PatientList';

const AppointmentStore = create((set, get) => ({
    NewAppointments: [],

    PatientList: [],

    isPatientListloading: true,

    isNewAppointmentsLoading: true,

    Appointment_data: null,

    isAppointmentloading: true,

    getNewAppointments: async () => {
        try {
            let res = await teleConsultationModel.post(`/getNewAppointemnts`, {
                Doc_id: getUserDetails()?.UID
            });

            if (res?.status === 200 && res?.data) {
                set({
                    NewAppointments: res?.data,
                    isNewAppointmentsLoading: false,
                });
            } else {
                set({
                    isNewAppointmentsLoading: false,
                });
                console.log('Failed to fetch Appointments. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isNewAppointmentsLoading: false,
            });
        }
    },

    getPatientList: async (startDate, endDate) => {
        try {
            let res = await teleConsultationModel.post(`/getPatientList`, {
                UID: getUserDetails()?.UID,
                startDate,
                endDate
            });

            if (res?.status === 200 && res?.data) {
                set({
                    PatientList: res?.data,
                    isPatientListloading: false,
                });
            } else {
                set({
                    isPatientListloading: false,
                });
                console.log('Failed to fetch Patientlist. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            
            set({
                isPatientListloading: false,
            });
        }
    },

    getOneAppointment: async (id) => {
        try {
            let res = await teleConsultationModel.get(`/getOneappointment/${id}`);

            if (res?.status === 200 && res?.data) {
                set({
                    Appointment_data: res?.data,
                    isAppointmentloading: false,
                });
            } else {
                set({
                    isAppointmentloading: false,
                });
                console.log('Failed to fetch Appointments. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isAppointmentloading: false,
            });
        }
    }
}))

export default AppointmentStore