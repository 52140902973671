import React from 'react'
import { Grid, IconButton } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Cookies from 'js-cookie';
import { Box, Typography, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import AppointmentStore from './DataRepository/AppointmentStore';
import { teleConsultationModel } from './Axios/AxiosConfig';

function Main() {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const { getNewAppointments, isNewAppointmentsLoading, NewAppointments } = AppointmentStore()

    const [DateType, setType] = useState("monthly")
    const [Appointmentdata, setData] = useState(null)


    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };


        updateDimensions();


        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const doctorDetailsCookie = Cookies.get('Doctor_details');

    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const getAppointmentData = async () => {
        try {
            let res = await axios.post('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/Appointmentdata', {
                DateType,
                Doc_id: Doctor_details.UID
            })
            if (res.status == 200) {
                setData(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const UpdateAppointment = async (AppointmentID, key, value) => {
        try {
            const res = await teleConsultationModel.put('/UpdateOneAppointment', {
                AppointmentID,
                key,
                value
            })

            if (res?.status == 200) {
                getNewAppointments();
                toast.success("Confirmed Appointment Successfully", {
                    position: 'top-center',
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });
            }

        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAppointmentData()
    }, [DateType])

    useEffect(() => {
        getNewAppointments()
    }, [])

    const chartData = {
        data: [
            { id: 0, value: Appointmentdata?.categoryCounts?.Respiratory, label: 'Respiratory' },
            { id: 1, value: Appointmentdata?.categoryCounts?.Mental, label: 'Mental' },
            { id: 2, value: Appointmentdata?.categoryCounts?.Gastro, label: 'Gastro' },
            { id: 3, value: Appointmentdata?.categoryCounts?.['Hair & Skin'], label: 'Hair & Skin' },
            { id: 4, value: Appointmentdata?.categoryCounts?.Physical, label: 'Physical' },
            { id: 5, value: Appointmentdata?.categoryCounts?.Women, label: 'Women' },
            { id: 6, value: Appointmentdata?.categoryCounts?.Sexual, label: 'Sexual' },
            { id: 7, value: Appointmentdata?.categoryCounts?.Metabolic, label: 'Metabolic' },
        ]
    };

    const COLORS = ["#75623F", '#918165', '#A3967E', '#C0b7a7', '#F4D2B3', '#EEBC8F', '#E69E56', '#DA6E0B'];

    return <div>
        <div className='flex flex-row items-center justify-between p-5 appointmentinsightstitle gap-5'>
            <p>Appointment Insights</p>
            <div className="select-container">
                <select value={DateType} className='styled-select' onChange={(e) => {
                    setType(e.target.value)
                }}>
                    <option value={"daily"}>Daily</option>
                    <option value={"weekly"}>Weekly</option>
                    <option value={"monthly"}>Monthly</option>
                    <option value={"yearly"}>Yearly</option>

                </select>
            </div>

        </div>

        <div className='flex justify-between p-5 gap-5'>
            <div className='Upcomingappointments'>
                <p>New Appointments</p>

                <div className='flex flex-col gap-5 mt-5' style={{ overflowY: "scroll", maxHeight: "500px" }}>
                    {NewAppointments &&
                        NewAppointments?.sort((a, b) => {
                            const dateA = new Date(a?.appointment_date);
                            const dateB = new Date(b?.appointment_date);

                            return dateB - dateA;
                        })?.map((e, i) => {
                            return (
                                <div className='w-100 p-5 bg-[#fdfdfd] br-5 newappointmentcard' style={{ borderRadius: "5px" }}>
                                    <div>
                                        <div>
                                            <div>
                                                <p>{e?.patient_name}</p>
                                            </div>
                                            <div className='flex gap-2 items-center'>
                                                <img src='/icons/date.png' alt="Date" style={{ width: "2rem" }} />
                                                <p>{e?.appointment_date.split('-').reverse().join('-')}</p>
                                            </div>
                                            <div className='flex gap-5 mt-2'>
                                                <div className='flex gap-2 items-center'>
                                                    <img src='/icons/clock.png' alt="Time" style={{ width: "2rem" }} />
                                                    <p>{e?.appointment_time}</p>
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <img style={{ width: "2rem" }}
                                                        src={e?.consultation_type == "Video" ? "/icons/video.png" : "/icons/Audio.png"} alt="Consultation Type" />
                                                    <p>{e?.consultation_type}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-5 mt-5'>
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    borderRadius: '0',
                                                    border: "1px solid #DA6E0B",
                                                    color: '#DA6E0B',
                                                    boxSizing: 'border-box',
                                                    borderRadius: "5px",
                                                    fontSize: "12px",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: "10px"
                                                }}

                                                component={Link} to={`/Reschedule/${e?.Appointment_id}`}
                                            >
                                                <EventRepeatIcon />&nbsp; Reschedule
                                            </Button>
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    textTransform: 'none',
                                                    borderRadius: '0',
                                                    backgroundColor: '#DA6E0B',
                                                    color: 'white',
                                                    fontSize: "12px",
                                                    border: 'none',
                                                    boxSizing: 'border-box',
                                                    borderRadius: "5px",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontWeight: 500,
                                                    padding: "10px"
                                                }}
                                                onClick={() => {
                                                    UpdateAppointment(e?.Appointment_id, 'appointment_status', 'confirmed')
                                                }}
                                            >
                                                <TaskAltIcon />&nbsp;  Confirm
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            <div>
                <div className='grid-container'>
                    <div className='gcontent'>
                        <p>Total Appointments</p>
                        <p>{Appointmentdata?.totalAppointments || 0}</p>
                    </div>
                    <div className='gcontent'>
                        <p>Completed</p>
                        <p>{Appointmentdata?.statusCounts?.completed || 0}</p>
                    </div>
                    <div className='gcontent'>
                        <p>Cancelled</p>
                        <p>{Appointmentdata?.statusCounts?.cancelled || 0}</p>
                    </div>
                    <div className='gcontent'>
                        <p>Rescheduled</p>
                        <p>{Appointmentdata?.statusCounts?.rescheduled || 0}</p>
                    </div>

                </div>

                <div className='Appointment-insights-container'>
                    <div className='Apppointment-insights-page'>
                        <div>

                        </div>

                        <div className='charts'>
                            <div ref={containerRef}>
                                {Appointmentdata?.weekCounts && (
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: ['week1', 'week2', 'week3'] }]}
                                        yAxis={[
                                            {
                                                label: 'No of Appointments',
                                            },
                                        ]}
                                        colors={["#8CEB8C", "#75623F", "#DA6E0B"]}
                                        width={350}
                                        height={300}
                                        series={[
                                            {
                                                label: 'Upcoming',
                                                data: Object.values(Appointmentdata?.weekCounts.week1)
                                            },
                                            {
                                                label: 'Cancelled',
                                                data: Object.values(Appointmentdata?.weekCounts.week2)
                                            },
                                            {
                                                label: 'Rescheduled',
                                                data: Object.values(Appointmentdata?.weekCounts.week3)
                                            },
                                        ]}
                                        borderRadius={20}
                                        sx={{
                                            [`.${axisClasses.left} .${axisClasses.label}`]: {
                                                transform: 'translate(-10px)',
                                                // fontSize: '12px', // Reduce font size for y-axis label
                                            },
                                            [`.${axisClasses.dataLabelClass}`]: {
                                                // fontSize: '10px', // Reduce font size for data labels
                                            },
                                        }}
                                        slotProps={{
                                            legend: {
                                                direction: 'row',
                                                position: { vertical: 'bottom', horizontal: 'center' },
                                                padding: 0,
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10,
                                                labelStyle: {
                                                    fontSize: 12,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                    <div className='Apppointment-insights-page'>
                        {/* <div>
                            <p>Wellness Categoires</p>
                        </div> */}

                        <div className='charts'>
                            <div ref={containerRef}>
                                {Appointmentdata?.categoryCounts && (
                                    <Box
                                        style={{
                                            // border: '1px solid red',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%'
                                        }}
                                    >
                                        <PieChart
                                            sx={{
                                                // border: "1px solid blue",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "auto"
                                            }}
                                            series={[
                                                {
                                                    data: chartData.data.map((item, index) => ({
                                                        id: item.id,
                                                        value: item.value,
                                                        label: item.label,
                                                        color: COLORS[index % COLORS.length]
                                                    })),
                                                    innerRadius: 40,
                                                    outerRadius: 100,
                                                    highlightScope: { highlighted: 'item', faded: 'global' },
                                                },
                                            ]}

                                            width={350}
                                            height={300}

                                            slotProps={{
                                                legend: {
                                                    // direction: 'row',
                                                    // position: { vertical: 'bottom', horizontal: 'center' },
                                                    padding: 0,
                                                    itemMarkWidth: 10,
                                                    itemMarkHeight: 10,
                                                    labelStyle: {
                                                        fontSize: 12,
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
}

export default Main