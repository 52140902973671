import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@mui/material';
import { teleConsultationModel } from '../Axios/AxiosConfig';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const CalendarContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
});

const DayBox = styled(Box)(({ theme, isSelected }) => ({
    padding: theme.spacing(2),
    backgroundColor: isSelected ? "#DA6E0B" : '#F1EFEC',
    color: isSelected ? '#fff' : '#000',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '0 5px',
}));


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: '10px',
    marginRight: '1rem',
    overflow: "auto",
    margin: "2rem",
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#E7E7DB',
    borderRadius: '10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
}));

const TimingCell = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    gap: '8px',
}));

const TimingButton = styled(Button)(({ theme }) => ({
    minWidth: '32px',
    height: '32px',
    // borderRadius: '50%',

    backgroundColor: '#C0B7A7',
    color: 'white',

}));

const DATE_SIZE = 32;

const ModeCell = styled(TableCell)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ModeButton = styled(Button)(({ theme }) => ({
    marginLeft: '8px',
}));

const Appointments = () => {
    const [selectedDay, setSelectedDay] = useState(dayjs());
    const [currentdate, setcurrentdate] = useState(new Date());
    const [consultationData, setconsultationData] = useState([]);

    const Navigate = useNavigate()

    const handleSelectDay = (newValue) => {
        setSelectedDay(newValue);
    };

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const getAppointmentData = async () => {
        try {

            let res = await teleConsultationModel.post('/getAppointmentbyDate', {
                Date: selectedDay.toISOString().split('T')[0],
                Doc_id: Doctor_details.UID
            })

            if (res.status == 200) {
                setconsultationData(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAppointmentData()
    }, [selectedDay])


    function convertTo24Hour(timeStr) {
        const date = new Date("1970-01-01 " + timeStr);
        return date.getHours() * 100 + date.getMinutes();
    }

    return (
        <div className='Appointments-page'>

            <div className='Appointment-selection-container'>
                <div className='Today-appointment'>
                    <div className='Today-appointment-title'>
                        <div>
                            <p>Appointments</p>
                            <p className='mt-2'>{selectedDay?.toISOString()?.split('T')[0]?.split('-')?.reverse()?.join('-')}</p>
                        </div>

                        <img src='./icons/todayappointmenticon.png' />
                    </div>
                    <div className='Appointment-count'>
                        {consultationData?.length}
                    </div>
                </div>

                <div className='Appointment-selector'>
                    {/* <div className='Appointment-picker'>
                        <div>
                            <p>{selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</p>
                        </div>
                        <div>
                            <IconButton><ArrowBackIosIcon onClick={handlePreviousWeek} style={{ cursor: 'pointer' }} /></IconButton>
                            <IconButton><ArrowForwardIosIcon onClick={handleNextWeek} style={{ cursor: 'pointer' }} /></IconButton>
                        </div>
                    </div> */}
                    {/* <CalendarContainer>

                        {weekDays.map((date, index) => (
                            <DayBox
                                key={index}
                                isSelected={currentdate.toDateString() === date.toDateString()}
                                onClick={() => handleDateClick(date)}
                            >
                                <Typography variant="body2">{date.toLocaleDateString('en-US', { weekday: 'short' })}</Typography>
                                <Typography variant="body1">{date.getDate()}</Typography>
                            </DayBox>
                        ))}

                    </CalendarContainer> */}


                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        "& > div": {
                            minWidth: 256,
                            display: "flex",
                            flexDirection: "column",
                        },
                        "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
                            width: 256,
                        },
                        "& .MuiTypography-caption": {
                            width: DATE_SIZE,
                            margin: 0,
                        },
                        "& .PrivatePickersSlideTransition-root": {
                            minHeight: DATE_SIZE * 6,
                            marginBottom: 0,
                            paddingBottom: 0,
                        },
                        '& .PrivatePickersSlideTransition-root [role="row"]': {
                            margin: 0,
                        },
                        "& .MuiPickersDay-dayWithMargin": {
                            margin: 0,
                        },
                        "& .MuiPickersDay-root": {
                            width: DATE_SIZE,
                            height: DATE_SIZE,
                        },
                        height: '18rem',
                        marginBottom: 0,
                        paddingBottom: 0,
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                openTo="day"
                                views={["day"]}
                                value={selectedDay}
                                onChange={handleSelectDay}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            style: {
                                                fontFamily: '"Poppins", sans-serif',
                                                fontWeight: 400,
                                            },
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                )}
                                sx={{
                                    ".Mui-selected": {
                                        backgroundColor: "#7FD67F !important",
                                        color: "#3B362A",
                                        fontFamily: '"Poppins", sans-serif',
                                        fontWeight: 500,
                                    },
                                    width: '100%',
                                    paddingBottom: 0,
                                    marginBottom: 0,
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                </div>
            </div>

            <div className='list-appointments'>
                <TableContainer style={{ borderRadius: "8px" }} className='AppointmentTable'>
                    <Table>
                        <StyledTableHead>
                            <TableRow >
                                <TableCell className='tableheadtitle' align='center'>Timing</TableCell>
                                <TableCell className='tableheadtitle' >Patient Name</TableCell>
                                <TableCell className='tableheadtitle' >Gender</TableCell>
                                <TableCell className='tableheadtitle' >Age</TableCell>
                                <TableCell className='tableheadtitle' >Mode of Consultation</TableCell>
                                <TableCell className='tableheadtitle' >Status</TableCell>
                                <TableCell className='tableheadtitle'></TableCell>
                                <TableCell className='tableheadtitle'></TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {consultationData?.sort((a, b) => convertTo24Hour(a.appointment_time) - convertTo24Hour(b.appointment_time))?.map((row, index) => (
                                <TableRow key={index} className='TableRowContainer'>
                                    <TableCell align='center'>
                                        {row.appointment_time}
                                    </TableCell>
                                    <TableCell align=''>
                                        <TimingButton>{row?.patient_name?.charAt(0)}</TimingButton> {row?.patient_name}</TableCell>
                                    <TableCell>{row?.gender || ''}</TableCell>
                                    <TableCell >{row?.patient_age}</TableCell>
                                    <TableCell >
                                        {row?.consultation_type} Consultation
                                    </TableCell>
                                    <TableCell>
                                        {
                                            row?.appointment_status === 'confirmed' ?
                                                <img src='./icons/Vector (2).png' style={{ width: '2rem' }}></img> : row?.appointment_status === 'rescheduled' ?
                                                    <img src='./icons/reschedule.png' style={{ width: '2rem' }}></img> : <></>
                                        }
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: "1px solid #DA6E0B ",
                                                color: '#DA6E0B',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "5px"
                                            }}
                                            component={Link} to={`/Patient/${row.Appointment_id}`}>
                                            View Profile
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                backgroundColor: '#DA6E0B',
                                                color: 'white',
                                                border: 'none',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "5px"
                                            }}
                                            
                                            href={`/Call/${row?.patient_id}/${row?.consultation_type}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Consult
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </div>

        </div>
    );
};

export default Appointments;
