import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Login from './Components/Auth/Login';
import OTP from './Components/Auth/OTP';
import Appointments from './Components/Appointments/Appointments';
import Main from './Components/Main';
import Call from './Components/Appointments/Call';
import Patient_Profile from './Components/Appointments/Patient_Profile';
import Reports from './Components/Reports/Reports';
import UploadReports from './Components/Reports/UploadReports';
import ViewReportList from './Components/Reports/ViewReportList';
import ViewReport from './Components/Reports/ViewReport';
import Prescription from './Components/Prescription/Prescription';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadPrescription from './Components/Prescription/UploadPrescription';
import ViewPrescription from './Components/Prescription/ViewPrescription';
import Chat from './Components/Chat/Chat';

import ProtectedRoute from './Components/Auth/ProtectedRoute';
import Profile from './Components/Appointments/Profile';
import Reschedule from './Components/Appointments/Reschedule';
import PatientList from './Components/Appointments/PatientList';
import ChatPage from './Components/Chat/ChatPage';
function App() {
  return <BrowserRouter>
    <ToastContainer />
    <Routes>
      <Route path='/Login' Component={Login} />
      <Route path='/LoginwithOTP' Component={OTP} />

      <Route path='/' element={<ProtectedRoute><Home></Home></ProtectedRoute>} >
        <Route path='/' element={<ProtectedRoute><Main /></ProtectedRoute>} />
        <Route path='AllChat' element={<ProtectedRoute><ChatPage /></ProtectedRoute>} />
        <Route path='Appointments' element={<ProtectedRoute><Appointments></Appointments></ProtectedRoute>} />
        <Route path='Patientlist' element={<ProtectedRoute><PatientList /></ProtectedRoute>} />
        <Route path='Reschedule/:id' element={<ProtectedRoute><Reschedule></Reschedule></ProtectedRoute>} />
        <Route path='Patient/:id' element={<ProtectedRoute><Patient_Profile /></ProtectedRoute>} />
        <Route path='Call/:id/:type' element={<ProtectedRoute><Call /></ProtectedRoute>} />
        <Route path='Reports/:id' element={<ProtectedRoute><Reports /></ProtectedRoute>} />
        <Route path='UploadReports/:id/:type' element={<ProtectedRoute><UploadReports /></ProtectedRoute>} />
        <Route path='ViewReportList' element={<ProtectedRoute><ViewReportList /></ProtectedRoute>} />
        <Route path='ViewReport' element={<ProtectedRoute><ViewReport /></ProtectedRoute>} />
        <Route path='Precription/:id' element={<ProtectedRoute><Prescription /></ProtectedRoute>} />
        <Route path="ViewPrescription" element={<ProtectedRoute><ViewPrescription /></ProtectedRoute>} />
        <Route path="Chat/:Patient_id/:Doctor_id" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
        <Route path='UploadPrescription' element={<ProtectedRoute><UploadPrescription /></ProtectedRoute>} />
        <Route path='/Profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      </Route>
    </Routes>
  </BrowserRouter>
}

export default App;
