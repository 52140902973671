import React, { useEffect, useState, useRef } from 'react'
import Contacts from './Contacts'
// import ChatContainer from './ChatContainer'
import Welcome from './Welcome'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Chat from './Chat'
import { io } from "socket.io-client";
import { RevolvingDot } from 'react-loader-spinner'
import { teleConsultationModel } from '../Axios/AxiosConfig'
import { getUserDetails } from '../Auth/AuthUtil'

export default function ChatPage() {
    let [Contact, SetContacts] = useState([])

    const socket = useRef();

    let [loading, isloading] = useState(true)

    let [Onlineuser, setonline] = useState([])

    let [lastseen, setlastseen] = useState([])

    let [currentuser, setcurrentuser] = useState('')

    const [currentChat, setCurrentChat] = useState(undefined);

    let navigate = useNavigate()

    let getdata = async () => {

        try {
            let res = await teleConsultationModel.post('AllPatients', {
                Doc_id: getUserDetails().UID
            })
            if (res.status === 200) {
                SetContacts(res.data)
                isloading(false)
            }
        }
        catch (err) {
            console.log(err)
        }


    }

    useEffect(() => {
        getdata()
    }, [currentuser]);

    const handleChatChange = (chat) => {
        setCurrentChat(chat);
    };

    return <>
        {
            loading ?
                <div className='Loader'>
                    <RevolvingDot
                        height="100%"
                        width="100%"
                        radius="50"
                        color="blue"
                        secondaryColor=''
                        ariaLabel="revolving-dot-loading"
                        wrapperClass="Loader"
                        visible={loading} />
                </div> :
                <div className='main'>
                    <div className="containes">
                        <Contacts Contacts={Contact} changeChat={handleChatChange} online={Onlineuser} />
                        {currentChat === undefined ? <Welcome /> :
                            <Chat currentChat={currentChat} socket={socket} online={Onlineuser} lastseen={lastseen} />}
                    </div>
                </div>
        }
    </>
}
