import create from 'zustand';
import { profileModel, teleConsultationModel } from '../Axios/AxiosConfig';

const VikritiStore = create((set, get) => ({
    Vikriti_data: [],
    isVikriti_dataloading: true,
    
    getVikritidata: async () => {
        try {
            let res = await profileModel.get(`/fetchVikritidata`);

            if (res?.status === 200 && res?.data) {
                set({
                    Vikriti_data: res?.data,
                    isVikriti_dataloading: false,
                });
            } else {
                set({
                    isVikriti_dataloading: false,
                });
                console.log('Failed to fetch Vikriti data. Status code:', res.status);
            }
        } catch (err) {
            console.log(err?.response?.data);
            set({
                isVikriti_dataloading: false,
            });
        }
    },
}))

export default VikritiStore