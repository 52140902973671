import axios from 'axios';
import { getAccessToken, refreshToken, clearTokens } from '../Auth/AuthUtil';
import {
    Profile_URL,
    Shop_URL,
    Chat_URL,
    Reports_URL,
    TeleConsultation_URL,
    Websocket_URL,
} from './config';

const createModel = (baseURL) => {
    const instance = axios.create({ baseURL });

    instance.interceptors.request.use(
        async (config) => {
            const token = getAccessToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const success = await refreshToken();
                if (success) {
                    originalRequest.headers['Authorization'] = `Bearer ${getAccessToken()}`;
                    return instance(originalRequest);
                } else {
                    clearTokens();
                    window.location.href = '/login';
                }
            }
           
            return Promise.reject(error);
        }
    );

    return instance;
};

export const profileModel = createModel(Profile_URL);
export const shopModel = createModel(Shop_URL);

export const chatModel = createModel(Chat_URL);
export const reportsModel = createModel(Reports_URL);
export const teleConsultationModel = createModel(TeleConsultation_URL);
export const websocketModel = createModel(Websocket_URL);
