import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRangePicker } from "materialui-daterange-picker";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Link } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AppointmentStore from '../DataRepository/AppointmentStore';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#E7E7DB',
    borderRadius: '10px',
}));

const TimingButton = styled(Button)(({ theme }) => ({
    minWidth: '32px',
    height: '32px',
    backgroundColor: '#C0B7A7',
    color: 'white',
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function PatientList() {
    const [open, setOpen] = useState(false);
    const { getPatientList, PatientList, isPatientListloading } = AppointmentStore();

    const [state, setState] = useState({
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
    });

    const [tabValue, setTabValue] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");

    const [filteredPatients, setFilteredPatients] = useState([]);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const setDateRange = (date) => {
        setState({ startDate: date.startDate, endDate: date.endDate });
    };

    useEffect(() => {
        getPatientList(
            dayjs(state.startDate).format('YYYY-MM-DD'),
            dayjs(state.endDate).format('YYYY-MM-DD')
        );
    }, [open]);

    useEffect(() => {
        let filtered = PatientList;

        if (tabValue === 1) {
            // Follow-Up: Check if patient_id is repeated
            const repeatedPatientIds = PatientList.reduce((acc, patient) => {
                acc[patient.patient_id] = (acc[patient.patient_id] || 0) + 1;
                return acc;
            }, {});
            filtered = PatientList.filter(patient => repeatedPatientIds[patient.patient_id] > 1);
        } else if (tabValue === 2) {
            // New: Check if patient_id is unique
            const uniquePatientIds = PatientList.reduce((acc, patient) => {
                acc[patient.patient_id] = (acc[patient.patient_id] || 0) + 1;
                return acc;
            }, {});
            filtered = PatientList.filter(patient => uniquePatientIds[patient.patient_id] === 1);
        }

        if (searchTerm) {
            const lowerSearchTerm = searchTerm.toLowerCase();
            filtered = filtered.filter(patient =>
                patient.patient_name.toLowerCase().includes(lowerSearchTerm) ||
                patient.patient_id.toString().includes(lowerSearchTerm) ||
                patient.Appointment_id.toString().includes(lowerSearchTerm)
            );
        }

        setFilteredPatients(filtered);
    }, [PatientList, tabValue, searchTerm]);

    return (
        <div className="m-5">
            <div className="flex justify-between items-center PatientlistTitle">
                <div className="PatientlistTitletext">

                    {PatientList.length > 0 ? <p>Patient List ({PatientList.length})</p> :
                        <p>Patient List</p>
                    }

                </div>
                <div className="Patientlistinput" style={{ position: 'relative', width: '60%' }}>
                    <input
                        placeholder="Search"
                        style={{
                            width: '100%',
                            padding: '10px 40px 10px 10px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            fontSize: '14px',
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            color: '#aaa',
                            pointerEvents: 'none',
                        }}
                    >
                        <SearchIcon />
                    </span>
                </div>

                <div>
                    <Button
                        style={{
                            textTransform: 'none',
                            border: "1px solid #DA6E0B",
                            color: '#DA6E0B',
                            borderRadius: "5px",
                            fontSize: "12px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            padding: "10px",
                        }}
                        onClick={handleClickOpen}
                    >
                        <DateRangeIcon /> &nbsp; Sort by Date
                    </Button>
                </div>
            </div>

            <div className='flex items-center justify-center'>
                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(newValue)}
                    textColor="primary"
                    indicatorColor="primary"
                    style={{ margin: '20px 0' }}
                >
                    <Tab label="All" />
                    <Tab label="Follow-Up" />
                    <Tab label="New" />
                </Tabs>
            </div>


            <div className='list-appointments'>
                <TableContainer style={{ borderRadius: "8px" }} className='AppointmentTable'>
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <TableCell className='tableheadtitle' align='center'>Timing</TableCell>
                                <TableCell className='tableheadtitle' >Patient Name</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Gender</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Age</TableCell>
                                <TableCell className='tableheadtitle' align='center'>Mode of Consultation</TableCell>
                                <TableCell className='tableheadtitle'></TableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {filteredPatients.map((row, index) => (
                                <TableRow key={index} className='TableRowContainer'>
                                    <TableCell align='center'>
                                        {row.appointment_time}
                                    </TableCell>
                                    <TableCell >
                                        <TimingButton>{row?.patient_name?.charAt(0)}</TimingButton>&nbsp; {row?.patient_name}
                                    </TableCell>
                                    <TableCell align='center'>{row?.gender || ''}</TableCell>
                                    <TableCell align='center'>{row?.patient_age}</TableCell>
                                    <TableCell align='center'>
                                        {row?.consultation_type} Consultation
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Button
                                            style={{
                                                width: '100%',
                                                textTransform: 'none',
                                                borderRadius: '0',
                                                border: "1px solid #DA6E0B ",
                                                color: '#DA6E0B',
                                                boxSizing: 'border-box',
                                                borderRadius: "5px",
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 500,
                                                padding: "5px",
                                            }}
                                            component={Link}
                                            to={`/Patient/${row.Appointment_id}`}
                                        >
                                            View Profile
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Select Date Range
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <DateRangePicker
                        open={open}
                        toggle={() => setOpen(!open)}
                        onChange={setDateRange}
                        initialDateRange={{
                            startDate: state.startDate,
                            endDate: state.endDate,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save Changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default PatientList;
