import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import Cookies from 'universal-cookie';
// import Cookies from 'js-cookie'
import { Password } from '@mui/icons-material'
import { teleConsultationModel } from '../Axios/AxiosConfig';
import { setTokens } from './AuthUtil';
import { Auth_URL } from '../Axios/config';
function Login() {
    let navigate = useNavigate()
    let [user, setUser] = useState()
    let [password, setPassword] = useState()
    const [error, setError] = useState('');

    const cookies = new Cookies();

    function addCountryCodeIfNumber(input) {
        if (!isNaN(input) && input.trim() !== '') {
            return '+91' + input;
        }
        return input;
    }

    const validateInput = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;
        if (emailRegex.test(input)) {
            return true;
        } else if (phoneRegex.test(input)) {
            return true;
        } else {
            return false;
        }
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setUser(inputValue);
        setError('');
    };

    const handleChange2 = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        setError('');
    };

    const handleSubmit = async (event) => {

        if (validateInput(user)) {
            try {
                let res = await axios.post(`${Auth_URL}Signin`, {
                    email: addCountryCodeIfNumber(user),
                    password
                })

                if (res.status == 200) {

                    const { accessToken, refreshToken, id } = res.data;
                    setTokens(accessToken, refreshToken);

                    let doctor_response = await teleConsultationModel.get(`getDoctor_details/${id}`)
                    if (doctor_response.status === 200) {
                        cookies.set('Doctor_details', JSON.stringify(doctor_response.data));
                        navigate('/')
                    }
                }
            }
            catch (error) {
                setError(error?.response?.data?.error)
            }
        } else {
            setError('Invalid input. Please enter a valid email or phone number.');
        }
    };

    const RequestOTP = async (event) => {
        event.preventDefault();
        if (validateInput(user)) {
            try {
                let res = await axios.post(`${Auth_URL}Passwordless-signin`, {
                    user: addCountryCodeIfNumber(user)
                })

                if (res.status == 200) {
                    cookies.set('OTP-Session', res?.data?.session);
                    cookies.set("user", addCountryCodeIfNumber(user));
                    navigate('/loginwithotp')
                }

            }
            catch (error) {
                setError(error?.response?.data?.error)
            }
        } else {
            setError('Invalid input. Please enter a valid email or phone number.');
        }
    };



    return <div className='Login-page'>
        <nav>
            <div>
                <img
                    src="https://v2.aiwell.in/static/media/Aiwell%20version%202%20website%20logo.d98414bba22690b67307.png"
                    className={`overflow-hidden transition-all w-40`}
                    alt=""
                />
            </div>
        </nav>
        <div className='Login-content-items'>
            <p>Sigin</p>
            <p>Welcome</p>
            <div class='login-text-inputs'>
                <div class="input-container">
                    <img src='./icons/login.png'></img>
                    <input type='text' placeholder='Enter mobile / Email id' onChange={handleChange} />
                </div>
                <div class="input-container">
                    <img src='./icons/create_password.png'></img>
                    <input type="password" placeholder='Enter password' onChange={handleChange2} />
                </div>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className='login-action-buttons'>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B', 
                        color: 'white', 
                        border: 'none', 
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}
                    onClick={handleSubmit}
                >
                    Sign in
                </Button>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#F4ECD6', 
                        color: 'white', 
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "Poppins', sans-serif",
                        fontWeight: 500,
                        color: "#DA6E0B",
                        border: "2px solid #DA6E0B",
                        padding: "10px"
                    }}
                    onClick={RequestOTP}
                >
                    Sign in with OTP
                </Button>
            </div>
            <p>By signed In you accept the terms & conditions</p>
        </div>
    </div>

}

export default Login