import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import loadingimg from '../Assets/images/loading image 1.png';
import { CircularProgress, Box, TextField, Button } from '@mui/material';
import dayjs from 'dayjs';
import cautionimg from '../Assets/images/Caution icon 1.png'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppointmentStore from '../DataRepository/AppointmentStore';
import { toast, Zoom } from 'react-toastify';
import TeleConsultationStore from '../DataRepository/TeleConsultationStore';
import { teleConsultationModel } from '../Axios/AxiosConfig';

function Reschedule() {
    const { id } = useParams()

    const { Appointment_data, getOneAppointment, isAppointmentloading } = AppointmentStore();

    const { getSlots, Slots, slotErrResponse } = TeleConsultationStore()

    const [consultation_reason, setconsultation_reason] = useState('None')

    const navigate = useNavigate()

    const [selectedDay, setSelectedDay] = useState(dayjs());

    const [selectedTime, setSelectedTime] = useState('')

    useEffect(() => {
        getOneAppointment(id)
        getSlots(Appointment_data?.doctor_id, selectedDay.format('YYYY-MM-DD'))
    }, [isAppointmentloading])

    const handleSelectDay = (date) => {
        if (!date) return;
        setSelectedDay(date);
        setSelectedTime('')
        getSlots(Appointment_data?.doctor_id, date.format('YYYY-MM-DD'));
    };

    const updateReschedule = async () => {
        if (selectedTime != "") {
            try {
                const res = await teleConsultationModel.put('/updateAppointmentForRescheduleDoctors', {
                    AppointmentID: id,
                    consultation_reason,
                    updatedTime: selectedTime,
                    updatedDate: selectedDay.format('YYYY-MM-DD')
                })

                if (res?.status === 200) {

                    toast.success("Appointment Rescheduled Successfully", {
                        position: 'top-center',
                        limit: 1,
                        style: {
                            fontSize: '14px', fontFamily: '"Poppins", sans-serif',
                            fontWeight: 500,
                            backgroundColor: '#FDFDFD',
                            color: '#3B362A'
                        },
                        transition: Zoom,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        closeButton: false,
                        theme: 'dark',
                        icon: <img src={cautionimg} alt="custom-icon" style={{ width: '2rem' }} /> // Custom icon
                    });

                    navigate(`/Patient/${id}`)
                }
            }
            catch (err) {
                console.log(err?.response?.date)
            }
        }
        else {
            toast.info("Please choose an available time slot", {
                position: 'top-center',
                limit: 1,
                style: {
                    fontSize: '14px', fontFamily: '"Poppins", sans-serif',
                    fontWeight: 500,
                    backgroundColor: '#FDFDFD',
                    color: '#3B362A'
                },
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                closeButton: false,
                theme: 'dark',
                icon: <img src={cautionimg} alt="custom-icon" style={{ width: '2rem' }} /> // Custom icon
            });
        }
    }

    return <div className='All-products-section-main m-5'>
        <nav className='flex flex-row p-2 items-center justify-between'>
            <div className='flex flex-row items-center'>
                <IconButton onClick={() => navigate(-1)}>
                    <ChevronLeft />
                </IconButton>
                <p className='mb-0'>Reschedule</p>
            </div>
        </nav>

        <div>
            {
                isAppointmentloading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: '100vh' }}>
                    <div style={{ position: "relative", width: "10rem", height: "10rem" }}>
                        <CircularProgress style={{ width: "100%", height: "100%", color: "#DA6E0B" }} thickness={1} />
                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={loadingimg} style={{ width: "100%", height: "100%" }} alt="Loading" />
                        </div>
                    </div>
                </div> :

                    <div className='mt-5'>

                        <div className='current-schedule'>
                            <p>Current Schedule</p>
                            <div>
                                <div>
                                    <p style={{ fontWeight: 500 }}>Date</p>
                                    <p className='mt-5'>{Appointment_data?.appointment_date}</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: 500 }}>Time</p>
                                    <p className='mt-5'>{Appointment_data?.appointment_time}</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: 500 }}>Mode</p>
                                    <p className='mt-5'>{Appointment_data?.consultation_type}</p>
                                </div>
                            </div>

                        </div>

                        <div>
                            <div className='Tele-consultation-doctor-slots mt-5'>
                                <div className='DatePicker-container'>

                                    <div>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0, width: "100%" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <StaticDatePicker
                                                    displayStaticWrapperAs="desktop"
                                                    openTo="day"
                                                    views={['day']}
                                                    value={selectedDay}
                                                    onChange={(newValue) => handleSelectDay(newValue)}
                                                    disablePast
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                style: {
                                                                    fontFamily: '"Poppins", sans-serif',
                                                                    fontWeight: 400,
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    sx={{
                                                        '.Mui-selected': {
                                                            backgroundColor: '#7FD67F !important',
                                                            color: '#3B362A',
                                                            fontFamily: '"Poppins", sans-serif',
                                                            fontWeight: 500,
                                                        },
                                                        '.Mui-selected:hover': {
                                                            backgroundColor: '#7FD67F',
                                                        },
                                                        '.Mui-selected:focus': {
                                                            backgroundColor: '#7FD67F',
                                                        },
                                                        '.MuiPickersDay-dayWithMargin': {
                                                            color: '#3B362A',
                                                            fontFamily: '"Poppins", sans-serif',
                                                            fontWeight: 500,
                                                        },
                                                        '.MuiPickersDay-dayWithMargin.Mui-selected': {
                                                            color: '#3B362A',
                                                            fontFamily: '"Poppins", sans-serif',
                                                            fontWeight: 500,
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </div>

                                    {
                                        slotErrResponse ? <div className='Slot-errreponse'>
                                            <img src={cautionimg}></img>
                                            <p>{slotErrResponse.message}</p>
                                        </div> : <div className='slotTitle'>
                                            <p>Choose Timing Slot</p>
                                            {
                                                <div className="Slot-container">

                                                    {Slots &&
                                                        Slots.map((slot, index) => (
                                                            <div
                                                                key={index}
                                                                className={`${selectedTime === slot.Time ? 'selected' : ''} ${slot.Status === 'Booked' ? 'booked' : ''}`}
                                                                onClick={() => {
                                                                    if (slot.Status === 'Free') {
                                                                        setSelectedTime(slot.Time);
                                                                    }
                                                                }}
                                                                style={{ pointerEvents: slot.Status === 'Booked' ? 'none' : 'auto', cursor: slot.Status === 'Booked' ? 'not-allowed' : 'pointer' }}
                                                            >
                                                                <p>{slot?.Time}</p>
                                                            </div>
                                                        ))

                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        !slotErrResponse && <div className='slotTitle'>
                                            <p>Reason For Rescheduling</p>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                onChange={(e) => {
                                                    setconsultation_reason(e?.target?.value)
                                                }}
                                                sx={{ marginTop: "1rem" }} />
                                        </div>
                                    }

                                </div>


                            </div>
                        </div>

                        <div className='flex justify-center sm:justify-center md:justify-end'>
                            <Button
                                style={{
                                    width: '15rem',
                                    textTransform: 'none',
                                    borderRadius: '5px',
                                    backgroundColor: '#DA6E0B',
                                    color: 'white',
                                    border: 'none',
                                    boxSizing: 'border-box',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px",

                                }}
                                onClick={() => {
                                    updateReschedule()
                                }}
                            >
                                Reschedule
                            </Button>
                        </div>


                    </div>
            }
        </div>

    </div>
}

export default Reschedule