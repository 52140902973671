import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Webcam from 'react-webcam';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UploadReports = () => {
    const { id, type } = useParams();
    const [seletedReportType, setReportType] = useState(null)
    const [report, setReport] = useState(null);
    const [allReports, setReports] = useState([]);
    const webcamRef = useRef(null);
    const fileInput = useRef();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);

    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: 'environment',
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        let reportsArray = e.target.files;
        for (let i = 0; i < reportsArray.length; i++) {
            let file = reportsArray[i];
            let filedata = await convertToBase64(file);
            setReports((prev) => [
                ...prev,
                {
                    file: filedata,
                    fileName: file?.name,
                },
            ]);
        }
    };



    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const base64String = fileReader.result.replace('data:', '').replace(/^.+,/, '');
                resolve(base64String);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleDelete = (index) => {
        setReports((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot().replace('data:', '').replace(/^.+,/, '');
        setReports((prev) => [
            ...prev,
            {
                file: imageSrc,
                fileName: `CapturedImage_${Date.now()}.jpg`,
            },
        ]);
        handleClose()
    }, [webcamRef]);

    const UploadReport = async () => {
        try {
            let res = await axios.post("https://mcqncj4lj7.execute-api.ap-south-1.amazonaws.com/dev/uploadreports", {
                reports: allReports,
                ReportId: Date.now().toString(),
                ReportUploadedby: Doctor_details?.UID,
                ReportType: report || `Report_${Date.now()}`,
                CreatedAt: new Date().toString(),
                UID: id,
                filetype: type.toLowerCase(),
                Comments: "Doctor Uploaded this Report"
            })

            if (res.status === 200) {
                navigate(-1)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='Upload-Reports'>
            <div className='Select-Reports'>
                <p>Select Your Report Type</p>
                <select onChange={(e) => {
                    setReportType(e.target.value)
                    setReport(e.target.value)
                }}>
                    <option value='ECG'>ECG</option>
                    <option value='Blood Test'>Blood Test</option>
                    <option value='Urine Test'>Urine Test</option>
                    <option value='X-ray'>X-ray</option>
                    <option value='Precription'>Prescription</option>
                    <option value='Others'>Others</option>
                </select>

                {seletedReportType === 'Others' ? (
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Enter your report type'

                        onChange={(e) => {
                            setReport(e.target.value)
                        }}

                        variant='outlined'
                    />
                ) : (
                    ''
                )}

                <label htmlFor='file-input'>
                    <div className='ChooseFiles'>
                        <img src='/icons/choose your file image.png' alt='Choose your file' />
                        {type === 'images' ? (
                            <input hidden type='file' id='file-input' ref={fileInput} multiple onChange={handleFileUpload} />
                        ) : (
                            <input hidden type='file' id='file-input' ref={fileInput} accept='.pdf' onChange={handleFileUpload} />
                        )}
                        <p>Choose your file</p>
                    </div>
                </label>


                {type === 'images' ? (
                    <>
                        <p className='text-center'>or</p>
                        <div className='TakePicturebtn'>
                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    backgroundColor: '#75623F',
                                    color: '#FDFDFD',
                                    borderRadius: '5px',
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: '10px',
                                }}
                                onClick={handleClickOpen}
                            >
                                <div className='btn-content'>
                                    <img src='/icons/camera icon.png' alt='Take pictures' />
                                    <p>Take pictures</p>
                                </div>
                            </Button>
                        </div>
                    </>
                ) : null}

                
            </div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent>
                    {open &&
                        <div className='camera-container'>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat='image/jpeg'
                                videoConstraints={videoConstraints}
                            />

                            <div>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        border: "1px solid #DA6E0B ",
                                        color: '#DA6E0B',
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}
                                    onClick={capture}>Capture</Button>
                                <Button
                                    style={{
                                        width: '100%',
                                        textTransform: 'none',
                                        borderRadius: '0',
                                        backgroundColor: '#DA6E0B', /* Background color */
                                        color: 'white', /* Text color for better contrast */
                                        border: 'none', /* Remove default border */
                                        boxSizing: 'border-box',
                                        borderRadius: "5px",
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        padding: "10px"
                                    }}

                                    onClick={() => handleClose()}
                                >Close Camera</Button>
                            </div>

                        </div>
                    }
                </DialogContent>

            </Dialog>

            <div className='Selected-Reports-item'>
                <div className='SelectedReports'>

                    {allReports.map((e, i) => (
                        <div className='SelectedReportCard' key={i}>
                            <IconButton style={{ marginLeft: 'auto' }} onClick={() => handleDelete(i)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            {type === 'images' ? (
                                <img src='/icons/Image icon-1.png' alt='Image icon' />
                            ) : (
                                <img src='/icons/pdf icon.png' alt='PDF icon' />
                            )}
                            <p className='text-center'>{e.fileName}</p>
                        </div>
                    ))}

                </div>

                {allReports.length > 0 ? <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}
                    onClick={UploadReport}>
                    Upload Report
                </Button>
                    : <></>
                }

            </div>
        </div>
    );
};

export default UploadReports;
