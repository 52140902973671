import React, { useEffect, useState, useRef } from 'react'
import { Button, IconButton, TextField, Card, CardContent } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Cookies from 'js-cookie';
import AudioRecorder from './AudioRecorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import usePrescriptionStore from '../DataRepository/Prescription';
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Dialog from '@mui/material/Dialog';
import AISuggestionsImg from '../Assets/images/Group 10923.png'
import { toast, Zoom } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem } from '@mui/material';
import { Checkbox, FormControlLabel, Grid, Typography, Autocomplete } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-h5-audio-player/lib/styles.css';
import Slide from '@mui/material/Slide';
import { Slider } from '@mui/material'
import Patient_Profile from '../Appointments/Patient_Profile';
import { useNavigate } from 'react-router-dom';

import Appointments from '../Appointments/Appointments';
import { profileModel, shopModel, teleConsultationModel } from '../Axios/AxiosConfig';
import DynamicContentRenderer from './DynamicContentRender';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UploadPrescription() {
    let { selectedPatientdetails, selectedAppointemntdetails, audioBase64, setSelectedPatientdetails } = usePrescriptionStore()
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [user, setuser] = useState([])
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [originalTranscript, setOriginalTranscript] = useState('');



    const handleTranscriptChange = (event) => {
        setTranscript(event.target.value);
    };

    const handleAudioPlayPause = () => {
        const audio = audioRef.current;

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play()
                .then(() => {
                    setIsPlaying(true);
                })
                .catch((error) => {
                    console.error("Audio play error: ", error);
                });
        }
    };

    const handleAudioEnded = () => {
        setIsPlaying(false);
    };
    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSliderChange = (e) => {
        const newTime = e.target.value;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const navigate = useNavigate()

    const handleOpenDialog = (index) => {
        setSelectedIndex(index);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedIndex(null);
    };

    const handleOpenDialog2 = (index) => {
        setOpen2(true);
    };

    const handleCloseDialog2 = () => {
        setOpen2(false);
    };

    const handleOpenDialog3 = (index) => {
        setOpen3(true);
    };

    const handleCloseDialog3 = () => {
        setOpen3(false);
    };

    const handleOpenDialog4 = (index) => {
        setOpen4(true);
    };

    const handleCloseDialog4 = () => {
        setOpen4(false);
    };

    const handleSeverityChange = (index, newValue) => {
        const updatedData = vikritidata.map((item, i) =>
            i === index ? { ...item, severity: newValue } : item
        );
        setvikritiData(updatedData);
    };

    const getVikritidata = async () => {
        try {
            let res = await profileModel.get(`/Vikriti/${selectedPatientdetails.UID}`);

            if (res?.status === 200 && res?.data) {
                setvikritiData(res?.data?.vikritiList)
            }
            else {
                console.log(`Failed to fetch Vikriti data. Status code: ${res.status}`)
            }

        } catch (err) {
            console.log(err?.response?.data);

        }
    }

    const handleSelectProduct = (product) => {
        if (selectedIndex !== null) {
            if (selectedIndex === product?.id) {
                handleInputChange(selectedIndex, '', 'medicine');
                handleInputChange(selectedIndex, '', 'id');
                handleInputChange(selectedIndex, null, 'companyid');
                handleInputChange(selectedIndex, null, 'deliverycharges');
                handleInputChange(selectedIndex, null, 'sku');
                handleInputChange(selectedIndex, null, 'price');
                handleInputChange(selectedIndex, null, 'desc');
                handleInputChange(selectedIndex, null, 'qty');
                handleInputChange(selectedIndex, null, 'imgurl');
                handleInputChange(selectedIndex, null, 'company');
            } else {
                handleInputChange(selectedIndex, product?.name, 'medicine');
                handleInputChange(selectedIndex, product?.id, 'id');
                handleInputChange(selectedIndex, product?.company_id, 'companyid');
                handleInputChange(selectedIndex, product?.deliverycharges, 'deliverycharges');
                handleInputChange(selectedIndex, product?.sku, 'sku');
                handleInputChange(selectedIndex, product?.price, 'price');
                handleInputChange(selectedIndex, product?.desc, 'desc');
                handleInputChange(selectedIndex, product?.qty, 'qty');
                handleInputChange(selectedIndex, product?.imgurl, 'imgurl');
                handleInputChange(selectedIndex, product?.company, "company");
            }
        }

        handleCloseDialog();
    };

    const removeSelectedProduct = (index) => {
        handleInputChange(index, '', 'medicine');
        handleInputChange(index, '', 'id');
        handleInputChange(index, null, 'companyid');
        handleInputChange(index, null, 'deliverycharges');
        handleInputChange(index, null, 'sku');
        handleInputChange(index, null, 'price');
        handleInputChange(index, null, 'desc');
        handleInputChange(index, null, 'qty');
        handleInputChange(index, null, 'imgurl');
        handleInputChange(index, null, 'company');
    }

    const [acknowledge, setacknowledge] = useState(false)
    const [herbalProducts, setHerbalProducts] = useState([])
    const [Recipes, setRecipes] = useState([])
    const [transcript, setTranscript] = useState('');
    const [includedCategories, setincludedCategories] = useState([])
    const [avoidedCategories, setavoidedCategories] = useState([])
    const [FoodtobeTaken, setFoodtobetaken] = useState([])
    const [Foodtobeavoided, setFoodtobeAvoided] = useState([])
    const [selectedRecipes, setSelectedRecipes] = useState([])
    const [Practices, setPractices] = useState([])
    const [yogalist, setyogalist] = useState(new Map())
    const [meditationList, setMeditationlist] = useState(new Map())
    const [Pranayama, PranayamaList] = useState(new Map())

    const [lifestylechanges, setlifestylechanges] = useState('')

    const [FollowUp, setfollowup] = useState('No')

    const [AllDiet, setAllDiet] = useState([])

    const [AllRecipes, setAllRecipes] = useState([])

    const [Medicineinputs, setMedicineinput] = useState([
        { medicine: '', Reason: "", dose: '', instruction: '', duration: '', quantity: 1, id: "" },]
    );

    const [selectedIndex, setSelectedIndex] = useState(null);

    const [Search, setsearch] = useState('')

    const handleAddField = () => {
        setMedicineinput([...Medicineinputs,
        {
            medicine: '', dose: '', Reason: "", instruction: '', duration: '',
            quantity: 1, id: "",
        }]);
    };

    const handleSearchChange = (e, value) => {
        const searchTerm = value?.toLowerCase() || '';
        setSearchTerm(searchTerm);
    };

    const handleInputChange = (index, inputValue, field) => {
        setMedicineinput((prevInputs) => {
            const newInputs = [...prevInputs];
            if (inputValue === null) {
                delete newInputs[index][field];
            } else {
                newInputs[index][field] = inputValue;
            }

            return newInputs;
        });
    };

    const doctorDetailsCookie = Cookies.get('Doctor_details');

    let Doctor_details = {};

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON?.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }

    function calculateAverageSeverity(severityMap) {
        const maxSeverityPerSymptom = 5;
        const totalSymptoms = severityMap.length;
        const maxTotalScore = totalSymptoms * maxSeverityPerSymptom;

        const totalSeverityScore = severityMap.reduce((sum, e) => sum + e.severity, 0);

        if (totalSymptoms === 0 || totalSeverityScore === 0) return 100;


        return Math.round((1 - totalSeverityScore / maxTotalScore) * 100);
    }

    const [vikritidata, setvikritiData] = useState([])

    const updateGoal = async () => {
        try {
            let res = await profileModel.post(`/updateGoal`, {
                userId: selectedPatientdetails.UID,
                SymptomsList: vikritidata,
                Total: calculateAverageSeverity(vikritidata)
            });
        }
        catch (err) {
            console.log(err?.response?.data);
        }
    }

    const [Medicaldetails, setMedicaldetails] = useState({
        Complaints: "", lab: "", history: "", diagnosis: "",
    })

    const handleMedicaldetails = (name, value) => {
        setMedicaldetails(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleRemoveField = (index) => {
        const newInputs = Medicineinputs.filter((_, i) => i !== index);
        setMedicineinput(newInputs);
    };

    const getherbalProducts = async () => {
        try {
            let res = await shopModel.get('/getAllproducts')
            if (res.status === 200) {
                setHerbalProducts(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const groupByCategory = (items) => {
        return items?.reduce((acc, item) => {
            if (!acc[item.categoryEng]) {
                acc[item.categoryEng] = [];
            }
            acc[item.categoryEng].push(item);
            return acc;
        }, {});
    };

    const getAllDiet = async () => {
        try {
            let res = await profileModel.get('/AllDiet')
            if (res.status === 200) {
                setAllDiet(groupByCategory(res.data))
            }
        }

        catch (err) {
            console.log(err.response.data)
        }
    }

    const getAllRecipes = async () => {
        try {
            let res = await profileModel.get('/AllRecipes')
            if (res.status === 200) {
                setAllRecipes(res.data)
            }
        }

        catch (err) {
            console.log(err.response.data)
        }
    }

    const getDietRecommendations = async () => {
        try {

            let res = await profileModel.post('/getDietRecommendations', {
                id: selectedPatientdetails.UID
            })

            if (res.status === 200) {
                setincludedCategories(groupByCategory(res?.data?.Suitable || []))
                setavoidedCategories(groupByCategory(res?.data?.Not_Suitable || []));
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const getRecipesRecommendations = async () => {
        try {
            let res = await profileModel.post('/getRecipiesRecommendations', {
                id: selectedPatientdetails.UID
            })
            if (res.status === 200) {
                setRecipes(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const getlifestylePractices = async () => {
        try {
            let res = await profileModel.get('/getlifestylePractices')
            if (res.status === 200) {
                setPractices(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleCheckboxChange = (item, isChecked, type) => {

        if (type === 'included') {
            setFoodtobetaken(prevState =>
                isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));

        }
        else if (type === 'avoided') {
            setFoodtobeAvoided(prevState =>
                isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));
        }

    };

    const handleSelectRecipe = (item, isChecked) => {
        setSelectedRecipes(prevState =>
            isChecked ? [...prevState, item] : prevState.filter(i => i.id !== item.id));
    };

    const AddYogalist = (yogalists) => {
        setyogalist((prev) => {
            let yoga = JSON?.parse(yogalists)
            const newlist = new Map(prev)
            if (newlist.has(yoga?.title)) {
                newlist.delete(yoga?.title)
            }
            else {
                newlist.set(yoga.title, yoga)
            }
            return newlist
        })
    }

    const AddMeditationlist = (lists) => {
        setMeditationlist((prev) => {
            let Meditation = JSON?.parse(lists)
            const newlist = new Map(prev)
            if (newlist.has(Meditation?.title)) {
                newlist.delete(Meditation?.title)
            }
            else {
                newlist.set(Meditation?.title, Meditation)
            }
            return newlist
        })
    }

    const AddPranayamalist = (lists) => {
        PranayamaList((prev) => {
            let pranayama = JSON?.parse(lists)
            const newlist = new Map(prev)
            if (newlist.has(pranayama?.title)) {
                newlist.delete(pranayama?.title)
            }
            else {
                newlist.set(pranayama?.title, pranayama)
            }
            return newlist
        })
    }

    const getPatientProfile = async () => {
        try {
            let res = await teleConsultationModel.post('getPatientProfile', {
                id: selectedAppointemntdetails?.Appointment_id
            })
            if (res.status == 200) {
                setuser(res.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const querySuggestion = `Based on the patient's profile, provide a list of appropriate herbs along with their usage guidelines. The profile details are as follows:
- Prakriti: ${user?.Prakriti_details?.result}
- Vikriti: ${user?.Vikriti_details?.Result}
- Symptoms: ${vikritidata?.map((e) => e?.symptoms).join(', ')}

The recommendations should:
1. Address imbalances in the doshas (Vata, Pitta, Kapha) based on the Prakriti and Vikriti details.
2. Specifically target and alleviate the mentioned symptoms.
3. Be structured in a format suitable for doctors to prescribe, including:
   - Herb name
   - Dosage
   - Method of preparation
   - Time of administration
   - Any relevant precautions or contraindications.

Ensure the suggestions are clear, actionable, and aligned with Ayurvedic principles.`;


    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const [AISuggestion, setAISuggestion] = useState('')

    const getUserQuerySuggestion = async () => {
        try {
            let res = await axios.post(`https://mvilni2derl6hhqy4arkoizbyq0pnuno.lambda-url.ap-south-1.on.aws/querySuggestion`, {
                query: querySuggestion
            })
            if (res.status == 200) {
                setAISuggestion(res.data.data)
            }
        }
        catch (err) {
            console.log(err.response.data)
        }
    }

    useEffect(() => {
        getherbalProducts()
        getDietRecommendations()
        getAllDiet()
        getAllRecipes()
        getlifestylePractices()
        getRecipesRecommendations()
        getPatientProfile()
        getVikritidata()
    }, [])

    useEffect(() => {
        getUserQuerySuggestion()
    }, [])

    const Categories = [
        { name: "All", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/all_product_icon.png", type: "All" },
        { name: "Tablets", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tablets.png", type: "Tablets" },
        { name: "Powders", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Powders.png", type: "Churna" },
        { name: "Oils", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Oils.png", type: "Oil" },
        { name: "Ghee", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Ghee.png", type: "Ghee" },
        { name: "Decoctions", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Decoctions.png", type: "Kashayam (Decoctions)" },
        { name: "Fermented", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Fermented.png", type: "Arishta" },
        { name: "Miscellaneous", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Miscellaneous.png", type: "Miscellaneous" },
        { name: "Syrups", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Syrups.png", type: "Syrup" },
        { name: "Tea", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/types+of+products/Tea.png", type: "Tea" }
    ];

    const handleRecommedations = async () => {
        try {
            const res = await teleConsultationModel.post('/UpdateAiwellRecommendations', {

                UID: selectedPatientdetails?.UID,

                RecipesRecommendations: selectedRecipes,

                DietRecommendations: {
                    Suitable: FoodtobeTaken,
                    Not_Suitable: Foodtobeavoided
                },

                YogaRecommendations: {
                    selectedPoses: Array.from(yogalist.values()),
                    unselectedPoses: Practices.yoga.filter(pose =>
                        !Array.from(yogalist.values()).some(selectedPose => selectedPose.id === pose.id)
                    )
                },

                PranayamaRecommendations: {
                    selectedPranayama: Array.from(Pranayama.values()),
                    unselectedPranayama: Practices?.pranayama?.filter(pose =>
                        !Array.from(Pranayama.values()).some(selectedPose => selectedPose.id === pose.id)
                    )
                }
            })

        }
        catch (err) {
            console.log(err)
        }
    }

    const UpdateAppointment = async (AppointmentID, key, value) => {
        try {
            const res = await teleConsultationModel.put('/UpdateOneAppointment', {
                AppointmentID,
                key,
                value
            })
        }
        catch (err) {
            console.log(err)
        }
    }



    const handlePatientDetailChanges = (e, field) => {
        const value = e.target.value;
        setSelectedPatientdetails({
            ...selectedPatientdetails,
            [field]: value,
        });
    };

    const handleSymptomChange = (index, newSymptom) => {
        const updatedData = [...vikritidata];
        updatedData[index].symptoms = newSymptom;
        setvikritiData(updatedData);
    };

    const handleAddRow = () => {
        setvikritiData([...vikritidata, { symptoms: '', severity: 0 }]);
    };

    const handleDeleteRow = (index) => {
        const updatedData = vikritidata.filter((_, i) => i !== index);
        setvikritiData(updatedData);
    };

    const [metrics, setMetrics] = useState({
        bloodPressure: "",
        randomBloodSugar: "",
        pulseRate: "",
        oximeterValue: "",
        respiratoryRate: "",
    });

    const [customMetrics, setCustomMetrics] = useState([]);

    // Handle built-in metrics change
    const handleMetricChange = (key, value) => {
        setMetrics((prev) => ({ ...prev, [key]: value }));
    };


    const handleCustomMetricChange = (index, field, value) => {
        const updatedCustomMetrics = [...customMetrics];
        updatedCustomMetrics[index][field] = value;
        setCustomMetrics(updatedCustomMetrics);
    };

    const handleAddCustomMetric = () => {
        setCustomMetrics([...customMetrics, { key: "", value: "" }]);
    };

    const handleDeleteCustomMetric = (index) => {
        setCustomMetrics(customMetrics.filter((_, i) => i !== index));
    };

    const [labTests, setLabTests] = useState([]);
    const [newTest, setNewTest] = useState("");

    const suggestions = [
        "Blood Test",
        "Urine Test",
        "MRI",
        "X-Ray",
        "Liver Function Test",
        "Kidney Function Test",
        "Thyroid Test",
        "Complete Blood Count (CBC)",
        "Hemoglobin Test",
        "Cholesterol Test",
        "Blood Sugar Test",
        "Vitamin D Test",
        "Electrolyte Panel",
        "Lipid Profile",
        "HIV Test",
        "Hepatitis Test",
        "Pregnancy Test",
        "Urinalysis",
        "Prostate Specific Antigen (PSA) Test",
        "Blood Culture",
        "Calcium Test",
        "Blood Coagulation Test (PT, APTT)",
        "Chest X-Ray",
        "Ultrasound Scan",
        "ECG (Electrocardiogram)",
        "Echocardiogram",
        "Barium Swallow Test",
        "CT Scan",
        "Endoscopy",
        "Arterial Blood Gas Test",
        "Pulmonary Function Test",
        "Sputum Test",
        "Skin Biopsy",
        "Bone Density Test",
        "Genetic Testing",
        "Allergy Test",
        "C-Reactive Protein (CRP) Test",
        "Lactate Dehydrogenase (LDH) Test",
        "Creatinine Test",
        "Bilirubin Test",
        "Amylase and Lipase Test",
        "Vitamin B12 Test",
        "Iron Studies",
        "Magnesium Test",
        "HBA1c (Hemoglobin A1c) Test",
        "Hepatitis C Test",
        "Pap Smear",
        "Colonoscopy",
        "BMD (Bone Mineral Density) Test",
        "Stool Test",
        "Urine Culture Test",
        "Saliva Test",
        "Fertility Test",
        "STD Test",
        "Sickle Cell Test",
        "Toxoplasmosis Test",
        "Dengue Test",
        "Malaria Test",
        "Tuberculosis (TB) Test",
        "Zika Virus Test",
        "COVID-19 Test"
    ];

    const handleAddTest = () => {
        if (newTest) {
            setLabTests([...labTests, newTest]);
            setNewTest("");
        }
    };


    const handleRemoveTest = (index) => {
        const updatedTests = [...labTests];
        updatedTests.splice(index, 1);
        setLabTests(updatedTests);
    };

    const combinedMetrics = {
        ...metrics,
        ...Object.fromEntries(
            customMetrics.map((metric) => [metric.key, metric.value])
        ),
    };

    const handlesubmitPrescription = async () => {
        try {
            if (!acknowledge) {
                toast.info("Please check the acknowledgment checkbox before proceeding", {
                    position: "top-center",
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                })
                return
            }

            setLoading(true);

            let payload = {
                Patient_details: selectedPatientdetails,
                Doctor_details,
                Appointment_details: selectedAppointemntdetails,
                Supplements: Medicineinputs,
                Medical_details: Medicaldetails,
                transcript: transcript,
                Diet: {
                    included: FoodtobeTaken,
                    avoided: Foodtobeavoided
                },
                VitalSigns: combinedMetrics,
                Goals: vikritidata,
                labTests,
                Practices: {
                    yoga: Array?.from(yogalist?.values()),
                    pranayama: Array?.from(Pranayama?.values()),
                    meditation: Array?.from(meditationList?.values())
                },
                Recipes: selectedRecipes,
                LifeStyle_changes: lifestylechanges?.split(','),
                Follow_up: FollowUp
            };

            if (audioBase64 || audioBase64?.trim() !== '') {
                payload.audio = audioBase64?.replace('data:', '')?.replace(/^.+,/, '');
            }

            let res = await axios.post(`https://f40aygafqj.execute-api.ap-south-1.amazonaws.com/dev/UploadPrescription`, payload);

            if (res.status === 200) {
                for (const e of Medicineinputs) {
                    if (e.id != '') {
                        const item = {
                            id: e.id,
                            productName: e?.medicine,
                            quantity: parseInt(e.quantity),
                            price: parseInt(e.price),
                            desc: e.desc,
                            companyid: e?.companyid,
                            deliverycharges: e?.deliverycharges,
                            src: e?.imgurl,
                            actualQty: e.qty,
                            sku: e?.sku,
                            company: e?.company
                        }
                        const response = await shopModel.post('/cart', {
                            userId: selectedPatientdetails.UID,
                            item
                        });
                    }
                }

                handleRecommedations();

                UpdateAppointment(selectedAppointemntdetails?.Appointment_id, 'appointment_status', 'completed');

                toast.success("Completed Appointment Successfully", {
                    position: 'top-center',
                    limit: 1,
                    transition: Zoom,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                });

                if (vikritidata && vikritidata.length != 0) {
                    updateGoal()
                }

                navigate(`/Precription/${selectedPatientdetails?.UID}`);

            }
        }
        catch (error) {
            console.log(error?.response?.data || error)
        }
        finally {
            setLoading(false);
        }
    }

    return <div className='UploadPrescription-page'>
        <div className='Prescription-sections'>
            <div className="flex flex-row justify-between items-center w-full">
                <div className='datectn'>{new Date().toDateString()}</div>
                <div>
                    <AudioRecorder onTranscriptChange={setTranscript}
                        onOriginalTranscriptChange={setOriginalTranscript}
                    />
                </div>
            </div>
            <div>

                <div className="audio-transcript-container">
                    {audioBase64 &&
                        <AudioPlayer
                            src={audioBase64}
                            onPlay={(e) => console.log("onPlay")}
                        />
                    }

                    <p>{originalTranscript}</p>

                    <textarea
                        className="transcript-textarea mt-3"
                        value={transcript}
                        onChange={handleTranscriptChange}
                        rows="4"
                    />

                </div>
            </div>

            <div className="flex flex-row items-center w-full Pdt flex-wrap gap-5" >

                <div className='flex flex-row gap-5 items-center'>
                    <p>Patient name :</p>
                    <input
                        type="text"
                        value={`${selectedPatientdetails?.firstname || ''} ${selectedPatientdetails?.lastname || ''}`}
                        onChange={(e) => {
                            const [first, last] = e.target.value.split(' ');
                            setSelectedPatientdetails({
                                ...selectedPatientdetails,
                                firstname: first || '',
                                lastname: last || '',
                            });
                        }}
                        className="border p-1 w-50"
                    />
                </div>

                <div className='flex flex-row gap-5 items-center'>
                    <p>Age :</p>
                    <input
                        type="number"
                        value={selectedPatientdetails?.dob}
                        onChange={(e) => handlePatientDetailChanges(e, 'dob')}
                        className="border p-1 w-50"
                    />
                </div>

                <div className='flex flex-row gap-5 items-center'>
                    <p>Gender :</p>
                    <select
                        value={selectedPatientdetails?.gender || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'gender')}
                        className="border p-1 w-50"
                    >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div className='flex flex-row gap-5 items-center'>
                    <p>BMI :</p>
                    <input
                        type="number"
                        value={selectedPatientdetails?.bmi}
                        onChange={(e) => handlePatientDetailChanges(e, 'bmi')}
                        className="border p-1 w-50"
                    />
                </div>

                <div className='flex flex-row gap-5 items-center'>
                    <p>Height :</p>
                    <input
                        type="text"
                        value={selectedPatientdetails?.height || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'height')}
                        className="border p-1 w-50"
                    />
                </div>

                <div className='flex flex-row gap-5 items-center'>
                    <p>Weight :</p>
                    <input
                        type="text"
                        value={selectedPatientdetails?.weight || ''}
                        onChange={(e) => handlePatientDetailChanges(e, 'weight')}
                        className="border p-1 w-50"
                    />
                </div>

            </div>

            <div>
                <p className='Prescription-sh-h'>Medical Details</p>
                <div className='Medical-details'>
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Complaints'

                        onChange={(e) => {
                            handleMedicaldetails('Complaints', e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Examination / Lab findings'

                        onChange={(e) => {
                            handleMedicaldetails('lab', e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}
                        id='outlined-basic'
                        label='Medical History'

                        onChange={(e) => {
                            handleMedicaldetails("history", e.target.value)
                        }}

                        variant='outlined'
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            },
                            '& .MuiInputBase-root': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        }}

                        id='outlined-basic'

                        label='Diagnosis'

                        onChange={(e) => {
                            handleMedicaldetails('diagnosis', e.target.value)
                        }}

                        variant='outlined'
                    />

                </div>
            </div>

            <div>
                <p className='Prescription-sh-h'>Vital Signs</p>
                <div className='p-2'>
                    <div>
                        <div className="flex flex-row items-center w-full flex-wrap gap-5" >
                            {[
                                { label: "Blood Pressure", key: "bloodPressure", unit: "mmHg" },
                                { label: "Random Blood Sugar", key: "randomBloodSugar", unit: "mg/dl" },
                                { label: "Pulse Rate", key: "pulseRate", unit: "bpm" },
                                { label: "Oximeter Value", key: "oximeterValue", unit: "%" },
                                { label: "Respiratory Rate", key: "respiratoryRate", unit: "breaths/min" },
                            ].map((metric) => (
                                <div key={metric.key}>
                                    <TextField
                                        fullWidth
                                        label={metric.label}
                                        className="medicine-input5"
                                        id="outlined-basic"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: '#F1EFEC', // Set background color for the input field
                                                '&.Mui-focused fieldset': {
                                                    border: 'none', // Remove border on focus
                                                },
                                            },
                                            '& .MuiInputBase-root': {
                                                '&:focus': {
                                                    outline: 'none',
                                                },
                                            },
                                            '& .MuiInputAdornment-root': {
                                                backgroundColor: '#F1EFEC', // Match background with TextField
                                                padding: '0 4px', // Add padding to align better
                                                borderRadius: '4px', // Optional: Add rounded edges
                                            },
                                        }}
                                        value={metrics[metric.key]}
                                        onChange={(e) =>
                                            handleMetricChange(metric.key, `${e.target.value}`)
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment>{metric.unit}</InputAdornment>
                                            ),
                                        }}
                                    />

                                </div>
                            ))}
                        </div>

                        <div className='mt-5'>
                            <p className='Prescription-sh-h'>Additional Metrics</p>
                            {customMetrics.map((customMetric, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    alignItems="center"
                                    sx={{ marginBottom: 1, marginTop: 0.5 }}
                                >
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            label="Key"
                                            value={customMetric.key}
                                            className='medicine-input5'
                                            id='outlined-basic'
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                                '& .MuiInputBase-root': {
                                                    '&:focus': {
                                                        outline: 'none',
                                                    },
                                                },
                                            }}
                                            onChange={(e) =>
                                                handleCustomMetricChange(index, "key", e.target.value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            label="Value"
                                            value={customMetric.value}
                                            className='medicine-input5'
                                            id='outlined-basic'
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                                '& .MuiInputBase-root': {
                                                    '&:focus': {
                                                        outline: 'none',
                                                    },
                                                },
                                            }}
                                            onChange={(e) =>
                                                handleCustomMetricChange(index, "value", e.target.value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDeleteCustomMetric(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                startIcon={<AddIcon />}
                                variant="outlined"
                                style={{
                                    textTransform: 'none',
                                    borderRadius: '0',
                                    backgroundColor: '#DA6E0B',
                                    color: 'white',
                                    border: 'none',
                                    boxSizing: 'border-box',
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                                onClick={handleAddCustomMetric}
                            >
                                Add Metric
                            </Button>
                        </div>

                        <div>
                            <div style={{ marginTop: "20px" }}>
                                <p className='Prescription-sh-h'>Suggested Lab Tests</p>
                                <div className='mt-2'>
                                    <Grid container spacing={5} alignItems="center" >
                                        <Grid item xs={10}>
                                            <Autocomplete
                                                freeSolo
                                                options={suggestions}
                                                value={newTest}
                                                onInputChange={(event, newValue) => setNewTest(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Enter or Select Lab Test"
                                                        placeholder="Type or select a lab test"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleAddTest}
                                                disabled={!newTest}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>


                                <div className="FoodtobeTaken mt-2">
                                    {
                                        labTests.map((e, i) => {
                                            return <div className='FoodtobeTakencard flex flex-row items-center justify-between'>
                                                <p>{e}</p>
                                                <IconButton onClick={() => handleRemoveTest(i)}>
                                                    <DeleteIcon sx={{ fontSize: "12px" }} />
                                                </IconButton>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <p className='Prescription-sh-h'>Goal Setting</p>
                <div style={{ padding: '1rem' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="symptoms table">
                            <TableHead style={{ backgroundColor: "#D4CEC3" }}>
                                <TableRow>
                                    <TableCell sx={{ width: '30%' }} align="center"
                                        style={{
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 500,
                                            color: "#3B362A",
                                            fontSize: "16px",
                                            padding: "10px"
                                        }}>Symptom</TableCell>
                                    <TableCell sx={{ width: '60%' }} align="center" style={{
                                        fontFamily: "'Poppins', sans-serif",
                                        fontWeight: 500,
                                        color: "#3B362A",
                                        fontSize: "16px",
                                        padding: "10px"
                                    }}>Severity</TableCell>
                                    <TableCell sx={{ width: '10%' }} align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: "#F8F8F4" }}>
                                {vikritidata.map((e, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row" sx={{ width: '30%' }}>
                                            <TextField
                                                value={e.symptoms}
                                                onChange={(event) => handleSymptomChange(i, event.target.value)}
                                                label="Enter symptom"
                                                sx={{ width: '100%', maxWidth: '200px' }}  // Adjust TextField to fit within the 30% column width
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '60%' }}>
                                            <Slider
                                                step={1}
                                                value={e?.severity}
                                                max={5}
                                                marks={[
                                                    { value: 0, label: '0' },
                                                    { value: 1, label: '1' },
                                                    { value: 2, label: '2' },
                                                    { value: 3, label: '3' },
                                                    { value: 4, label: '4' },
                                                    { value: 5, label: '5' }
                                                ]}
                                                sx={{
                                                    '& .MuiSlider-thumb': {
                                                        color: "#8CEB8C"
                                                    },
                                                    '& .MuiSlider-track': {
                                                        color: "#8CEB8C"
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        color: "#acc4e4"
                                                    },
                                                    '& .MuiSlider-active': {
                                                        color: "#8CEB8C"
                                                    }
                                                }}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => {
                                                    const imageMap = {
                                                        0: 'url(../images/None.png)',
                                                        1: 'url(../images/Low.png)',
                                                        2: 'url(../images/Moderate.png)',
                                                        3: 'url(../images/High.png)',
                                                        4: 'url(../images/Severe.png)',
                                                        5: 'url(../images/Extreme.png)'
                                                    };

                                                    return < div
                                                        style={{
                                                            backgroundImage: imageMap[value],
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: '2rem',
                                                            backgroundPosition: "center",
                                                            width: '2.5rem',
                                                            height: '50px',
                                                        }}
                                                    >

                                                    </div>
                                                }}
                                                color='#DA6E0B'
                                                valueLabelStyle={{ color: '#DA6E0B' }}
                                                onChange={(event, newValue) => handleSeverityChange(i, newValue)}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ width: '10%' }}>
                                            <IconButton
                                                onClick={() => handleDeleteRow(i)}
                                                color="error"
                                                size="small"
                                            >
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className='mt-5 flex justify-end'>
                        <Button
                            onClick={handleAddRow}
                            variant="contained"
                            style={{
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: "5px",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: "10px"
                            }}
                        >
                            Add Symptom
                        </Button>
                    </div>

                </div>

            </div>

            <div
                className={`${isFullScreen ? "fixed inset-0 z-50 bg-white" : "relative"} transition-all border rounded-lg shadow-md`}
            >
                <div
                    className={`${isFullScreen ? "fixed inset-0 z-50 bg-white" : "relative"} transition-all border rounded-lg shadow-md`}
                >
                    <Card
                        className={`relative overflow-hidden ${isFullScreen ? "h-full w-full" : "h-48 w-full"
                            }`}
                    >
                        <div className="bg-[#F4ECD6] p-4 border-b AISuggestionimg">
                            <img src={AISuggestionsImg}></img>
                            <h2 className="text-lg font-bold">AI Suggestions</h2>
                        </div>
                        <CardContent
                            className={`overflow-y-auto ${isFullScreen ? "h-[calc(100%-4rem)]" : "h-40"
                                } p-5 pr-4`}
                        >
                            <div
                                // dangerouslySetInnerHTML={{ __html: AISuggestion }}
                                className='AIHTMltext'
                                style={{ padding: '1rem', lineHeight: '1.5' }}
                            // style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5' }}
                            >
                                <DynamicContentRenderer content={AISuggestion} />
                            </div>

                        </CardContent>
                        <button
                            className="absolute top-2 right-2 bg-[#7C7870] p-1 rounded-md shadow hover:bg-gray-300 focus:outline-none color-[#fdfdfd]"
                            onClick={toggleFullScreen}
                        >
                            {isFullScreen ? (
                                <CloseFullscreenIcon />
                            ) : (
                                <OpenInFullIcon />
                            )}
                        </button>
                    </Card>
                </div>
            </div>

            <div>
                <p className='Prescription-sh-h'>Herbal Supplemnents</p>
                <div>
                    {Medicineinputs.map((input, index) => (
                        <div key={index} className='flex flex-row gap-5 items-center'>
                            <div className='Medical-details mt-5'>
                                <TextField
                                    className='medicine-input'
                                    label="Medicine Name"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    InputProps={{
                                        endAdornment: (
                                            !input.medicine ? (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" size="small" onClick={() => handleOpenDialog(index)}>
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : input?.id !== '' ? (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" size="small" onClick={() => removeSelectedProduct(index)}>
                                                        <HighlightOffIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null
                                        ),

                                    }}
                                    value={input.medicine}
                                    onChange={(event) => handleInputChange(index, event.target.value, "medicine")}
                                    variant="outlined"
                                />

                                <TextField
                                    label="Quantity"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    className='medicine-input2'
                                    value={input.quantity}
                                    onChange={(event) => handleInputChange(index, event.target.value, "quantity")}
                                    variant="outlined"
                                />
                                <TextField
                                    className='medicine-input3'
                                    label="Dose"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.dose}
                                    onChange={(event) => handleInputChange(index, event.target.value, "dose")}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Duration"
                                    className='medicine-input4'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.duration}
                                    onChange={(event) => handleInputChange(index, event.target.value, "duration")}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Instruction"
                                    className='medicine-input5'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input.instruction}
                                    onChange={(event) => handleInputChange(index, event.target.value, "instruction")}
                                    variant="outlined"
                                />
                                <TextField
                                    label="Reason for Medication"
                                    className='medicine-input5'
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:focus': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                    id='outlined-basic'
                                    value={input?.Reason}
                                    onChange={(event) => handleInputChange(index, event.target.value, "Reason")}
                                    variant="outlined"
                                />
                            </div>
                            <div style={Medicineinputs?.length > 1 ? { visibility: "visible" } : { visibility: 'hidden' }}>
                                <IconButton>
                                    <HighlightOffIcon
                                        onClick={() => handleRemoveField(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='add-more' onClick={handleAddField}>
                    Add more <AddCircleOutlineIcon />
                </div>

            </div>
            <div>
                <p className='Prescription-sh-h'>Diet Recommendations</p>
                <div className='DietRecommendation-add flex flex-row justify-between mt-2 gap-3'>
                    <div className='dietrc'>
                        <p>Food to be included</p>
                        <div className='D_dietc'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <IconButton onClick={handleOpenDialog2}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div>
                                <div className="FoodtobeTaken">
                                    {
                                        FoodtobeTaken.map((e) => {
                                            return <div className='FoodtobeTakencard flex flex-row items-center justify-between'>
                                                <p>{e.englishName}</p>
                                                <IconButton onClick={() => {
                                                    handleCheckboxChange(e, false, "included")
                                                }}>
                                                    <DeleteIcon sx={{ fontSize: "12px" }} />
                                                </IconButton>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dietrc'>
                        <p>Food to be avoided</p>
                        <div className='D_dietc'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <IconButton onClick={handleOpenDialog3}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div>
                                <div className="FoodtobeTaken">
                                    {
                                        Foodtobeavoided.map((e) => {
                                            return <div className='FoodtobeTakencard flex flex-row items-center justify-between'>
                                                <p>{e.englishName}</p>
                                                <IconButton onClick={() => {
                                                    handleCheckboxChange(e, false, "avoided")
                                                }}>
                                                    <DeleteIcon sx={{ fontSize: "12px" }} />
                                                </IconButton>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>Recipes</p>
                <div className='dietrc'>
                    <div className='D_dietc'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <IconButton onClick={handleOpenDialog4}>
                                <AddIcon />
                            </IconButton>
                        </div>
                        <div>
                            <div className="FoodtobeTaken">
                                {
                                    selectedRecipes.map((e) => {
                                        return <div className='FoodtobeTakencard flex flex-row items-center justify-between'>
                                            <p>{e.recipe}</p>
                                            <IconButton onClick={() => {
                                                handleSelectRecipe(e, false)
                                            }}>
                                                <DeleteIcon sx={{ fontSize: "12px" }} />
                                            </IconButton>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Lifestyle Practices
                </p>
                <div className='DietRecommendation-add flex flex-row justify-between mt-2 gap-3'>

                    <div className='practices-card'>
                        <p>Yoga</p>
                        <select onChange={(e) => AddYogalist(e.target.value)}>
                            {
                                Practices?.yoga?.map((e) => {
                                    return <option value={JSON?.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(yogalist.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>

                    </div>

                    <div className='practices-card'>
                        <p>Pranayama</p>
                        <select onChange={(e) => AddPranayamalist(e.target.value)}>
                            {
                                Practices?.pranayama?.map((e) => {
                                    return <option value={JSON?.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(Pranayama.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>
                    </div>

                    <div className='practices-card'>
                        <p>Meditation</p>
                        <select onChange={(e) => AddMeditationlist(e.target.value)}>
                            {
                                Practices?.meditation?.map((e) => {
                                    return <option value={JSON?.stringify(e)}>{e?.title}</option>
                                })
                            }
                        </select>
                        <ul>
                            {
                                Array.from(meditationList.values()).map((e) => {
                                    return <li>{e?.title}</li>
                                })
                            }
                        </ul>
                    </div>

                </div>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Lifestyle Changes
                </p>
                <textarea className='w-full mt-3 lifestylechanges' rows="5" style={{ resize: "none" }}
                    onChange={(e) => {
                        setlifestylechanges(e.target.value)
                    }}>
                </textarea>
            </div>
            <div>
                <p className='Prescription-sh-h'>
                    Follow up
                </p>
                <input type='date' onChange={(e) => {
                    setfollowup(e.target.value.split('-').reverse().join('-'))
                }}>
                </input>
            </div>
            <div>
                <div className='formbtn'>
                    <div className='checkbox'>
                        <input type='checkbox' onChange={(e) => {
                            setacknowledge(e?.target?.checked)
                        }}></input>
                        <p>I, {Doctor_details?.name}, acknowledge the submission of the prescription form for the patient named {selectedPatientdetails?.firstname + " " + selectedPatientdetails?.lastname} on {new Date().toString()}, and confirm the review of all relevant patient details and prescribed medications.</p>
                    </div>
                </div>
            </div>
            <div style={{ width: "10rem", margin: "auto" }}>
                <Button
                    style={{
                        width: '100%',
                        textTransform: 'none',
                        borderRadius: '0',
                        backgroundColor: '#DA6E0B',
                        color: 'white',
                        border: 'none',
                        boxSizing: 'border-box',
                        borderRadius: "5px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 500,
                        padding: "10px"
                    }}

                    onClick={handlesubmitPrescription}
                >
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </div>
        </div>
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseDialog}
        >

            <div className='Herbal-products-container w-auto'>
                <div className='flex justify-between flex-row w-auto'>
                    <IconButton onClick={handleCloseDialog}>
                        <ArrowBackIcon />
                    </IconButton>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon></CloseIcon>
                    </IconButton>
                </div>
                <p className='Prescription-sh-h'>Herbal Products</p>
                <div className='Search-bar'>
                    <div className='search-box'>
                        <img src='/icons/search icon 1.png'></img>
                        <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />
                    </div>
                </div>

                <div className='ListofCategories'>
                    {Categories.map((e) => {
                        return (
                            <div onClick={() => {
                                if (e?.type?.toLowerCase()?.includes(Search?.toLowerCase())) {
                                    setsearch('all')
                                }
                                else {
                                    setsearch(e.type)
                                }
                            }}>
                                <img src={e.img}></img>
                                <p className={e?.type?.toLowerCase()?.includes(Search?.toLowerCase()) && Search != '' ? 'lineactive' : ""}>{e.name}</p>
                            </div>
                        )

                    })}
                </div>

                <div className="herbal-products">
                    {herbalProducts.filter((s) => {
                        if (Search === '') {
                            return s
                        }
                        if (Search === 'all') {
                            return s
                        }
                        else if (s?.name?.toLowerCase()?.includes(Search?.toLowerCase()) || s?.ProductType?.toLowerCase()?.includes(Search?.toLowerCase())) {
                            return s
                        }
                    }).filter((s) => s.onsale).map((e) => {
                        return (
                            <div key={e?.id} className="herbal-product-card">
                                <div className="img-container">
                                    <img src={e?.imgurl} alt={e?.name} />
                                    <input type="checkbox" className="overlay-checkbox"
                                        onChange={() => handleSelectProduct(e)} />
                                </div>
                                <p>{e?.name}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </Dialog>

        <Dialog
            open={open2}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog2}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Diet</p>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <p className='Prescription-sh-h'>Food to be included</p>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#7FD67F", borderRadius: "3px" }}>
                        </div>
                    </div>
                </div>

                <Autocomplete
                    sx={{ margin: '10px' }}
                    options={Object?.values(AllDiet).flat().filter((e) => !Foodtobeavoided.some((i) => i.id === e.id))}
                    getOptionLabel={(option) => option.englishName}
                    onInputChange={handleSearchChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Search diets" variant="outlined" fullWidth />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id} onClick={() => {
                            setSearchTerm('')
                            handleCheckboxChange(option, FoodtobeTaken.find((i) => i?.id === option?.id) ? false : true, 'included');
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={FoodtobeTaken.find((i) => i?.id === option?.id)}
                                    />
                                }
                                label={option.englishName}
                            />
                        </li>
                    )}
                />


                <div>
                    {Object?.keys(AllDiet)?.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category}</h3>

                            <div className="item-grid">
                                {AllDiet[category]?.map(item => (

                                    <div className="food-item">
                                        <div className='food-item-img-container mb-2'>
                                            <img src={item?.imageLink} alt={item?.englishName} className="food-image" />
                                            <input type="checkbox" className="overlay-checkbox"
                                                checked={FoodtobeTaken.find((i) => i?.id === item?.id)}
                                                style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                                onChange={(e) => {
                                                    handleCheckboxChange(item, e.target.checked, "included")
                                                }} />
                                        </div>
                                        <p className='mt-5'>{item?.englishName}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
                </div>

            </div>
        </Dialog>

        <Dialog
            open={open3}
            TransitionComponent={Transition}

            onClose={handleCloseDialog3}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='Herbal-products-container'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Diets</p>
                    <div className='flex flex-row justify-center items-center gap-2'>
                        <p className='Prescription-sh-h'>Food to be avoided</p>
                        <div style={{ width: "20px", height: "20px", backgroundColor: "#F15050", borderRadius: "3px" }}>
                        </div>
                    </div>
                </div>

                <Autocomplete
                    sx={{ margin: '10px' }}
                    options={Object?.values(AllDiet).flat().filter((e) => !FoodtobeTaken.some((i) => i.id === e.id))}
                    getOptionLabel={(option) => option.englishName}
                    onInputChange={handleSearchChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Search diets" variant="outlined" fullWidth />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id} onClick={() => {
                            setSearchTerm('')
                            handleCheckboxChange(option, Foodtobeavoided.find((i) => i?.id === option?.id) ? false : true, 'avoided');
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Foodtobeavoided.find((i) => i?.id === option?.id)}
                                    />
                                }
                                label={option.englishName}
                            />
                        </li>
                    )}
                />

                <div>
                    {Object?.keys(AllDiet)?.map((category, index) => (
                        <div key={index} className="category">
                            <h3>{category}</h3>
                            <div className="item-grid">
                                {AllDiet[category]?.filter((e) => !FoodtobeTaken.some((i) => i.id === e.id)).map(item => (

                                    <div className="food-item">
                                        <div className='food-item-img-container mb-2'>
                                            <img src={item?.imageLink} alt={item?.englishName} className="food-image" />
                                            <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                                onChange={(e) => {
                                                    handleCheckboxChange(item, e.target.checked, "avoided")
                                                }} />
                                        </div>
                                        <p className='mt-5'>{item?.englishName}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </Dialog>

        <Dialog
            open={open4}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog4}
            aria-describedby="alert-dialog-slide-description"
        >

            <div className='Herbal-products-container'>

                <div className='flex flex-row justify-between items-center mb-3'>
                    <p className='Prescription-sh-h'>Recipes</p>
                </div>

                <Autocomplete
                    sx={{ margin: '10px' }}

                    options={AllRecipes}

                    getOptionLabel={(option) => option.recipe}

                    onInputChange={handleSearchChange}

                    renderInput={(params) => (
                        <TextField {...params} label="Search Recipes" variant="outlined" fullWidth />
                    )}

                    renderOption={(props, option) => (
                        <li {...props} key={option.id} onClick={() => {
                            setSearchTerm('')
                            handleSelectRecipe(option, selectedRecipes.find((i) => i?.id === option?.id) ? false : true)

                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedRecipes.find((i) => i?.id === option?.id)}
                                    />
                                }

                                label={option.recipe}
                            />
                        </li>
                    )}
                />

                <div className="item-grid justify-center">

                    {AllRecipes?.map(item => (
                        <div className="food-item">
                            <div className='food-item-img-container mb-2'>
                                <img src={item?.imageLink} alt={item?.recipe} className="food-image" />
                                <input type="checkbox" className="overlay-checkbox" style={{ width: "auto", height: "auto", top: "5px", right: "8px" }}
                                    onChange={(e) => {
                                        handleSelectRecipe(item, e.target.checked)
                                    }} />
                            </div>
                            <p className="mt-5 w-full" style={{ wordWrap: "break-word", width: "10rem" }}>{item?.recipe}</p>
                        </div>
                    ))}

                </div>

            </div>
        </Dialog>

    </div>
}

export default UploadPrescription