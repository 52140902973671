import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import usePrescriptionStore from '../DataRepository/Prescription';
import { profileModel } from '../Axios/AxiosConfig';
function Prescription() {
  const { id } = useParams()
  const { setPrescription } = usePrescriptionStore()
  const [Prescriptiondata, setPrescriptionData] = useState([])
  const doctorDetailsCookie = Cookies.get('Doctor_details');
  const navigate = useNavigate()

  let Doctor_details = {};

  if (doctorDetailsCookie) {
    try {
      Doctor_details = JSON.parse(doctorDetailsCookie);
    } catch (error) {
      console.log('Error parsing Doctor_details cookie:', error);
    }
  }

  const getPrescription = async () => {
    try {
      const res = await profileModel.post(`/getPrescriptionfoDoctor`, {
        PatientID: id,
        DoctorId: Doctor_details.PID
      })
      if (res.status == 200) {
        setPrescriptionData(res.data)
      }
    }

    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getPrescription()
  }, [])


  const handlePrescription = (data) => {
    setPrescription(data)
    navigate('/ViewPrescription')
  }

  return <div className='Reports-main-page mb-10'>
    <div className='Reports-text-box'>
      <p>Prescription</p>
    </div>
    {Prescriptiondata.length > 0 ?
      <><div className='ListReports mb-10'>
        {Prescriptiondata?.sort((a, b) => new Date(b.Date) - new Date(a.Date))?.map((e, i) => {
          return <div className="ListReportCard" style={{ width: "100%" }} onClick={() => {
            handlePrescription(e)
          }}>
            <div>
              <p>{e.Patient_details.firstname} {e.Patient_details.lastname}</p>
              <p>{e.Date}</p>
              <p>Prescribed by {e.Doctor_details.name}</p>
            </div>
            <div>
              <img src='/icons/Rx.png' alt='PDF icon' />
            </div>
          </div>
        })
        }
      </div>
        <div className='pBtnsection'>
          <div className='uploadReportbtn'>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                backgroundColor: '#DA6E0B',
                color: 'white',
                border: 'none',
                boxSizing: 'border-box',
                borderRadius: "5px",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: "10px"
              }}
              onClick={() => {
                navigate('/UploadPrescription')
              }}
            >
              Add a Prescription
            </Button>
          </div>
        </div>
      </>
      :
      <div className='No-reports'>
        <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/no+prescription.png'></img>
        <p>No Prescription found</p>
        <div>
          <Button
            style={{
              width: '100%',
              textTransform: 'none',
              borderRadius: '0',
              border: "1px solid #DA6E0B ",
              color: '#DA6E0B',
              boxSizing: 'border-box',
              borderRadius: "5px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              padding: "10px"
            }}
            onClick={() => {
              navigate('/UploadPrescription')
            }}
          >
            Add a Prescription
          </Button>
        </div>
      </div>
    }
  </div>
}

export default Prescription