import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import usePrescriptionStore from '../DataRepository/Prescription'
import axios from "axios"
import AddIcon from '@mui/icons-material/Add';
import io from 'socket.io-client'
import SendIcon from '@mui/icons-material/Send';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AudioWave from './AudioWave'
import Lottie from 'lottie-react'
import typinganimation from '../Assets/animations/typinganimation.json'

function Chat({ currentChat }) {
    let [msg, setmsg] = useState('')
    const textAreaRef = useRef(null);

    let { patient_id, doctor_uid, doctor_id, Appointment_id } = currentChat

    Appointment_id = 'Appointment_1736768872477'

    patient_id = '81b34d3a-60b1-70a2-7713-f4a199ce2da9'

    console.log(Appointment_id)


    // console.log(doctor_uid, doctor_id)

    const [online, setOnline] = useState([])

    let [messages, setmessages] = useState([])

    const WS_URL = `wss://a7uoyq8x2j.execute-api.ap-south-1.amazonaws.com/production/?userId=${doctor_id}`;

    let [arrivalMessage, setArrivalMessage] = useState(null);

    let scrollRef = useRef();
    const socket = useRef();

    const sendIconStyle = {
        width: '3rem',
        height: '3rem',
        display: 'flex',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#DA6E0B',
        color: 'white',
    };

    const addIconStyle = {
        width: '3rem',
        height: '3rem',
        display: 'flex',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        border: '1px solid #DA6E0B',
    };

    useEffect(() => {
        socket.current = io('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev');
        socket.current.emit("add-user", doctor_id);
        socket.current.on("get-users", (users) => {
            setOnline(users)
        });
    }, [currentChat])

    const { sendJsonMessage, lastJsonMessage, readyState } =
        useWebSocket(WS_URL, {
            onOpen: () => {
                console.log("WebSocket connection established.");
            },
            share: false,
            shouldReconnect: () => true,
        });

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: "subscribe",
                data: {
                    channel: "general-chatroom",
                },
            });
        }
    }, [readyState]);


    useEffect(() => {
        if (lastJsonMessage) {
            console.log(`Got a new message: ${lastJsonMessage}`);
            const { event, data } = lastJsonMessage;
            arrivalMessage && setmessages((prev) => [...prev, arrivalMessage]);
        }
    }, [lastJsonMessage, currentChat]);


    const sendChat = () => {
        if (msg.trim() === '') return;

        sendJsonMessage({
            action: "sendMessage",
            message: msg,
            type: "text",
            from: doctor_id,
            to: patient_id,
            conversationId: `${currentChat?.Appointment_id}`,
        });

        setmsg('');
    };


    let Collectmsgs = async () => {
        try {
            const res = await axios.post('https://z2t1vcmobj.execute-api.ap-south-1.amazonaws.com/dev/getMessages', {
                conversationId: currentChat?.Appointment_id
            });

            if (res.status === 200) {
                setmessages(res.data);
            }

        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        Collectmsgs()
    }, [lastJsonMessage, currentChat]);

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "20px";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    };

    useEffect(
        resizeTextArea,
        [msg]);


    useEffect(() => {
        arrivalMessage && setmessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return <div className='chat-box'>
        <div className='chat-head'>
            <div>

                <div className='flex flex-row p-2 ' style={{ borderRadius: "8px" }}>
                    <p>{currentChat?.patient_name}</p>
                </div>
                <div className='p-2 pt-1 typing-text'>
                    {
                        lastJsonMessage?.from === patient_id && lastJsonMessage?.event === 'typing' &&
                        <p>typing...</p>
                    }
                </div>
            </div>
        </div>
        <div className='chat-body' ref={scrollRef}>
            {
                messages.map((e, i) => {
                    return <div
                        key={i}
                        className={e?.from === doctor_id ? "message own" : "message"}
                    >
                        <span>{e.type === "image" ? <img src={e.message}></img>
                            : e.type === "audio" ? <AudioWave audioUrl={e.message} /> :
                                e.message
                        }
                        </span>
                    </div>
                })

            }

            {
                lastJsonMessage?.from === patient_id && lastJsonMessage?.event === 'typing' &&
                <div className='typinganimation-container'>
                    <Lottie animationData={typinganimation}></Lottie>
                </div>
            }

        </div>
        <div className='chat-footer'>
            <div className='chat-footer-action-inputs'>
                <div className='chat-footer-textarea'>
                    <textarea ref={textAreaRef} placeholder="Message..." value={msg} style={{ color: "black" }}
                        type='text' onChange={(e) => { setmsg(e.target.value) }} row="1" />
                </div>

                <div>
                    {
                        msg.length > 0 ?
                            <Button style={sendIconStyle} onClick={sendChat}>
                                <SendIcon style={
                                    { color: "#FDFDFD" }
                                } fontSize='small'></SendIcon>
                            </Button> :

                            <Button style={addIconStyle}>
                                <AddIcon></AddIcon>
                            </Button>
                    }
                </div>
            </div>

        </div>
    </div>







}

export default Chat