import React, { useState, useEffect } from 'react'
import { Avatar } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
export default function Contacts({ Contacts, changeChat, online }) {

    const [currentSelected, setCurrentSelected] = useState(undefined);

    const [Search, setsearch] = useState('')

    const changeCurrentChat = (i, e) => {
        setCurrentSelected(i);
        changeChat(e);
    };

    return <>
        <div className='Display'>
            {/* <div className='chat-section-header'>

            </div> */}
            <div className='Contact-info'>
                <h4>Chats</h4>
            </div>
            <div className='chat-search'>
                <div className='chat-search-box'>
                    <SearchOutlined />
                    <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />
                </div>
            </div>

            <div className='Contact-section'>
                {Contacts?.filter((s) => {
                    if (Search === "") {
                        return s;
                    }
                    else if (s?.patient_name?.toLowerCase().includes(Search.toLowerCase())) {
                        return s;
                    }
                }).map((e, i) => {
                    return (
                        <div className='Contact-list flex items-center' key={e?.patient_id}
                            style={currentSelected === i ? { backgroundColor: "#fdfdfd" } : {}}>
                            {online.find((id) => id?.userId === e?._id) ? <><Avatar src={e.Profile_pic} />
                                <div className='Online-dot m-0'></div></> : <Avatar src={e?.Profile_pic} />}
                            <div className='User-info' onClick={() => {
                                changeCurrentChat(i, e)
                            }}>
                                <h2>{e?.patient_name}</h2>
                                {/* <h6>{online.find((id)=>id.userId===e._id)?<span style={{color:"green"}}>Online</span>:"Offline"}</h6> */}
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </div>

    </>
}