import React, { useContext } from 'react';
import Sidebar, { SidebarItem } from './Sidebar';
import '../App.css';
import Cookies from 'js-cookie';
import { IconButton } from '@mui/material';
import { Outlet, Link } from 'react-router-dom';
import homeicon from './Assets/images/Home.png'
import homeactiveicon from './Assets/images/Home white icon.png';
import Chaticon from './Assets/images/Group 13858.png'
import { useLocation } from 'react-router-dom';

import { SidebarContext } from './Sidebar';

function Home() {
    const doctorDetailsCookie = Cookies.get('Doctor_details');
    let Doctor_details = {};

    const location = useLocation();

    if (doctorDetailsCookie) {
        try {
            Doctor_details = JSON.parse(doctorDetailsCookie);
        } catch (error) {
            console.log('Error parsing Doctor_details cookie:', error);
        }
    }



    return (
        <div className="home-container flex">
            <Sidebar Doctor_details={Doctor_details}>
                {[
                    { path: '/', label: 'Home', icon: homeicon, activeIcon: homeactiveicon },
                    { path: '/Appointments', label: 'Appointments', icon: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/My+Appointment.png', activeIcon: homeactiveicon },
                    { path: '/Patientlist', label: 'Patient list', icon: 'https://aiwellassets.s3.ap-south-1.amazonaws.com/patient+icon.png', activeIcon: homeactiveicon },
                    { path: '/AllChat', label: 'Chat', icon: Chaticon, activeIcon: homeactiveicon },
                ].map(({ path, label, icon, activeIcon }) => (
                    <Link to={path} key={path}>
                        <SidebarItem
                            icon={
                                <img
                                    src={icon}
                                    alt={label}
                                    style={{ width: "2rem" }}
                                />
                            }
                            text={label}
                            active={location.pathname === path}
                        />
                    </Link>
                ))}

            </Sidebar>
            <div className="home-content flex-1">
                <nav className='home-content-nav flex flex-row p-2 mb-50'>
                    <div className="flex-1">
                        <p>Hello, {Doctor_details?.name}</p>
                    </div>
                    <div>
                        <IconButton>
                            <img src='/icons/notification.png' alt="Notifications" style={{ width: "2rem" }} />
                        </IconButton>
                    </div>
                </nav>

                <div className=''>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Home;
